<template>
  <div class="card clickable" v-loading="isLoading">
    <div class="card-header " >
      <div class="d-flex">
        <h3 class="m-0 pr-4 flex-grow-1 collapsible collapsed" data-toggle="collapse" :data-target="'#collapseCard' + this.type" aria-expanded="false" :aria-controls="'collapseCard' + this.type">{{label}}</h3>
        <el-tooltip :content="$t('message.new')" placement="top">
          <base-button type="primary" icon round size="sm" v-on:click="onCreate" :disabled="project.state === 'ARCHIVED'">
            <i class="fas fa-plus p-0"></i>
          </base-button>
        </el-tooltip>
      </div>
    </div>

    <div class="collapse" :id="'collapseCard' + this.type">
      <el-table
        class="table-responsive table-compact"
        header-row-class-name="thead-light"
        row-key="key"
        :data="options.filter(o => o.type==this.type)"
      >
        <el-table-column label="Text" prop="name" sortable />
        <el-table-column label width="88">
          <template v-slot="{row}">
            <el-tooltip :content="$t('message.edit')" placement="top">
              <base-button type="link" size="sm" @click="onEdit(row)" :disabled="project.state === 'ARCHIVED'">
                <i class="fas fa-edit text-black"></i>
              </base-button>
            </el-tooltip>
            <el-tooltip :content="$t('message.delete')" placement="top">
              <base-button type="link" size="sm" @click="onDelete(row)" :disabled="project.state === 'ARCHIVED'">
                <i class="fas fa-trash text-black"></i>
              </base-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

    </div>
    <option-form
      v-if="item"
      :item="item"
      :label="label"
      :show.sync="showForm"
      @changed="onChanged" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Bootstrap } from 'bootstrap';

import OptionForm from "./OptionForm";

export default {
  name: "options",
  components: { OptionForm },
  props: {
    project: { type: Object },
    type: { type: String },
    label: { type: String }
  },
  computed: {
    ...mapGetters(["options"])
  },
  data() {
    return {
      isLoading: true,
      items: [],
      item: {},
      showForm: false,
      options: [],
      optionsProjectId: ''
    };
  },
  computed: {
    //...mapGetters(["options"])
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["loadProjectOptions"]),
    onChanged() {
      this.showForm = false;
      this.isLoading = true;
      this.load();
    },
    onCreate(e) {
      this.item = { project_id: this.optionsProjectId, type: this.type };
      this.showForm = true;
    },
    onDelete: async function(item) {
      try {
        await this.$confirm(
          `Wollen Sie diese ${this.label} wirklich löschen?`,
          `${this.label} löschen`,
          {
            confirmButtonText: this.$t("message.yes"),
            cancelButtonText: this.$t("message.no"),
            type: "warning"
          }
        );
      } catch (error) {
        return;
      }

      this.isLoading = true;
      await this.$store.dispatch("deleteOption", { id: item.id });
      this.load();
    },
    onEdit(item) {
      this.item = { ...item };
      this.showForm = true;
    },
    load: async function() {
      let projectMatches = await this.$store.dispatch('loadProjectMappingByChild', {
          type: 'project', 
          child_id: this.project.id
        })

      if (projectMatches.length) {
        this.optionsProjectId = projectMatches[0].parent_id;
      } else {
        this.optionsProjectId = this.project.id;
      }

      this.options = await this.loadProjectOptions(this.optionsProjectId);
      this.isLoading = false;
    }
  }
};
</script>
<style>

.card-header h3.collapsible:after { 
    content: "\f0d8";
    font-family: "Font Awesome 5 Free";
    padding-left: 10px;
}
.card-header h3.collapsible.collapsed:after { 
    content: "\f0d7";
}

</style>
