<template>
  <modal @update:show="onShowChange" :show="show">
    <h6
      slot="header"
      class="modal-title"
    >Unterprojekt erstellen</h6>
    <base-input :label="$t('message.projectname')" v-model="subProject.projectName">
     
    </base-input>
   <el-table
      class="table-responsive table-compact"
      header-row-class-name="thead-light"
      row-key="id"
      :data="locations"
      @selection-change="handleSelectionChange">
      <el-table-column
        type="selection"
        width="55" />
      <el-table-column :label="$t('message.einsatzorte')" prop="address.name" header-align="left" align="left" class-name="text-left"/>
    </el-table>

    <template slot="footer">
      <base-button type="link" class="ml-auto" @click="onClose">{{$t('message.cancel')}}</base-button>
      <base-button type="primary" @click="onSave" :disabled="subProject.selectedAddresses.length <= 0 || subProject.projectName === ''">{{$t('message.save')}}</base-button>
    </template>
  </modal>
</template>

<script>
import { mapGetters } from "vuex"
import { required } from "vuelidate/lib/validators"
import Vue from 'vue' 

export default {
  name: "sub-project-form",
  props: {
    project: {
      type: Object
    },
    show: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapGetters(["locations"])
  },
  data() {
    return {
      isLoading: false,
      subProject: {
        projectName: '',
        selectedAddresses: []
      }
    };
  },
  watch: {
    async show() {
      if(this.show) {
        this.load()
      }
    }
  },
  validations: {
    costAccount: { required },
  },
  methods: {
    load: async function() {
      if (this.project) {
        await this.$store.dispatch("getAddressesByProjectId", this.project.id);
      }
      this.isLoading = false;
    },
    onClose() {
      this.onShowChange(false);
    },
    onSave: async function() {
      const loading = this.$loading({
        lock: true,
        text: this.$t('message.copying-in-progress'),
      })

      const payload = {
        projectName: this.subProject.projectName,
        addresses: this.subProject.selectedAddresses.map(a => a.address_id)
      }

      return Vue.http.post(`project/${this.project.id}/sub-copy`, payload)
        .then(res => {
          loading.close()
          const id = res.body.id;
          this.$router.push({ path: `/projects`})
        })
        .catch(error => {
          loading.close()
          this.$notify({
            title: 'Error',
            message: error.body.message,
            type: 'error'
          })
        })      

      this.$emit("save", this.subProject);
      this.onClose();
    },
    onShowChange(value) {
      this.$emit("update:show", value);
    },
    handleSelectionChange (e) {
      this.subProject.selectedAddresses = e
    }

  },
  mounted() {
    // this.load();
  }
};
</script>

<style scoped>
  .text-left {
    text-align: left;
  }
</style>