<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <!-- <h6 class="h2 d-inline-block mb-0">{{$route.name}}</h6> -->
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="primary" @click="save">{{$t('message.save')}}</base-button>
          <base-button size="sm" type="neutral" @click="$router.push({ path: '/admin/vehicles'})">{{$t('message.cancel')}}</base-button>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">{{$t('message.vehiclesInformation')}}</h3>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <!-- Form groups used in grid -->
            <div class="row">
              <div class="col-md-4">
                <base-input v-model="item.type" :label="$t('message.description')" :placeholder="$t('message.description')"/>
              </div>
              <div class="col-md-4">
                <base-input v-model="item.license" :label="$t('message.licensePlate')" :placeholder="$t('message.licensePlate')"/>
              </div>
              <div class="col-md-4">
                <base-input :label="$t('message.pt_matching_vehicles')">
                  <el-select v-model="item.pt_matching_id"
                             class="select-primary"
                             :placeholder="$t('message.pt_matching_vehicles')">
                    <el-option v-for="option in matches"
                              class="select-primary"
                              :value="option.id"
                              :label="option.pt_option_name"
                              :key="option.id">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    name: 'RolesView',
    components: {
   
    },
    props: ['data', 'employees'],
    computed: {
      ...mapGetters([
        'vehicles',
        'pt_options_matching'
      ])
    },
    data() {
      return {
        item: {},
        matches: []
      }
    },
    methods: {
      ...mapActions(['loadPtMatchingByType']),
      async load() {
          await this.$store.dispatch('loadPtMatchingByType', 'FAHRZEUG').then( matches => {
            this.matches = matches
          })
      },
      getId() {
        return this.$route.params.id=='new' ? null : this.$route.params.id;
      },
      save() {
        if (this.getId()) {
          this.$store.dispatch('updateVehicle', {id: this.getId(), data: this.item})
        } else {
          this.$store.dispatch('createVehicle', {data: this.item})
        }
        this.$router.push({ path: '/admin/vehicles'})
      }
    },
    mounted () {
      if (!this.getId()) {
        this.item = {}
      } else {
        this.$store.dispatch('getVehicle', {id: this.getId()}).then(item=>{
          this.item=item
        })
      }
      this.load();
    }
  }
</script>