<template>
  <div class="card" v-loading="isLoading">
    <div class="card-header">
      <div class="d-flex">
        <h3 class="m-0 pr-4 flex-grow-1">{{ $t('message.milestones')}}</h3>
        <el-tooltip :content="$t('message.new')" placement="top">
          <base-button type="primary" icon round size="sm" @click="onCreate" :disabled="project.state === 'ARCHIVED'">
            <i class="fas fa-plus p-0"></i>
          </base-button>
        </el-tooltip>
      </div>
    </div>

    <el-table
      class="table-responsive table-compact"
      header-row-class-name="thead-light"
      row-key="id"
      :data="items">
      <el-table-column :label="$t('message.name')" prop="name" sortable>
        <template v-slot="{row}">
          {{row.name }}<template v-if="row.projectAddress"> ({{ row.projectAddress.address.name}})</template>
        </template>
      </el-table-column>
      <el-table-column :label="$t('message.dueDate')" prop="due" sortable>
        <template v-slot="{row}">
          {{ row.due | date }}
        </template>
      </el-table-column>
      <el-table-column label width="88">
        <template v-slot="{row}">
          <el-tooltip :content="$t('message.edit')" placement="top">
            <base-button type="link" size="sm" @click="onEdit(row)" :disabled="project.state === 'ARCHIVED'">
              <i class="fas fa-edit text-black"></i>
            </base-button>
          </el-tooltip>
          <el-tooltip :content="$t('message.delete')" placement="top">
            <base-button type="link" size="sm" @click="onDelete(row)" :disabled="project.state === 'ARCHIVED'">
              <i class="fas fa-trash text-black"></i>
            </base-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <milestone-form :show.sync="showForm" :milestone="item" v-if="item" @changed="onChanged"></milestone-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MilestoneForm from "./MilestoneForm";
import { required, minLength, between } from "vuelidate/lib/validators";

export default {
  name: "milestones",
  components: {
    MilestoneForm
  },
  props: {
    project: {
      type: Object
    }
  },

  data() {
    return {
      isLoading: true,
      items: [],
      item: null,
      showForm: false,
      roles: [
        {
          order: 1,
          name: "SPL"
        },
        {
          order: 2,
          name: "PL"
        },
        {
          order: 3,
          name: "Designer"
        },
        {
          order: 4,
          name: "Techniker"
        }
      ]
    };
  },
  methods: {
    ...mapActions([
      'getMilestonesForProject'
    ]),
    getRoles(row) {
      // TODO: Does not work, check data structure
      if (!row.role) {
        return "-";
      }

      const rolesWithEmployees = ["Sub"];
      row.role
        .map(item => {
          if (rolesWithEmployees.includes(item) && !!row.employeeAmount) {
            return item + " (" + row.employeeAmount + ")";
          }
          return item;
        })
        .join(", ");
    },
    load: async function() {
      this.items = await this.getMilestonesForProject(this.project.id);
      this.isLoading = false;
    },
    onChanged() {
      this.isLoading = true;
      this.load();
    },
    onCreate() {
      this.item = {
        project_id: this.project.id,
        type: 'MILE',
        phase: '',
        internal: true
      };

      this.showForm = true;
    },
    onDelete: async function(item) {
      try {
        await this.$confirm(
          `Wollen Sie diesen Milestone wirklich löschen?`,
          `Miletone löschen`,
          {
            confirmButtonText: this.$t("message.yes"),
            cancelButtonText: this.$t("message.no"),
            type: "warning"
          }
        );
      } catch (error) {
        return;
      }

      this.isLoading = true;
      await this.$store.dispatch("deleteMilestone", { id: item.id })

      this.load();
    },
    onEdit(item) {
      this.item = item;
      this.showForm = true;
    }
  },
  mounted() {
    this.load();
  }
};
</script>
