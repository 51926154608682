<template >
    <modal @update:show="onClose" :show="show" class="debriefing">
        <h6 slot="header" class="modal-title">Erweitertes Debriefing Projekt</h6>
        <div v-loading="isLoading">
            <div class="row">
                <div class="col-md-9">
                    <base-input label="Event" disabled v-model="project.name">
                    </base-input>
                </div>
                <div class="col-md-3">
                    <base-input :label="$t('message.date')">
                        <apa-date-picker v-model="formDate" :conf="dateConfig" :placeholder="$t('global.input_select_placeholder')" />
                    </base-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <base-input label="Navision-Nr." disabled v-model="orderNumbers">
                    </base-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <base-input :label="$t('message.costAccount')" :disabled="setDisabled" v-model="costAccount">
                    </base-input>
                </div>
                <div class="col-md-4">
                    <base-input label="PT-Nr.-Kreis" disabled v-model="project.pt_number">
                    </base-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <table class="locations-table" width="100%">
                        <tr>
                            <th class="form-control-label">Location</th>
                            <th class="form-control-label">Ort</th>
                            <th class="form-control-label">Veranstaltungszeitraum</th>
                            <th class="form-control-label">Montagezeitraum</th>
                            <th class="form-control-label">De-Montagezeitraum</th>
                        </tr>
                        <tr v-for="(location, index) in projectLocations" :key="index">
                            <td>
                                <span ref="locationName">{{ location.name }}</span>
                            </td>
                            <td>
                                <span ref="locationCity">{{ location.city }}</span>
                            </td>
                            <td>
                                Von: <span ref="eventStart">{{ location.from }}</span><br>
                                Bis: <span ref="eventEnd">{{ location.to }}</span>
                            </td>
                            <td>
                                <span v-if="checkDate(mountFrom[index])" class="error">Bitte Datum auswählen.</span><br>
                                Von: <span class="assembly-date"><apa-date-picker v-model="mountFrom[index]" :conf="dateConfig" :placeholder="$t('global.input_select_placeholder')" /></span><br>
                                <span v-if="checkDate(mountTo[index])" class="error">Bitte Datum auswählen.</span><br>
                                Bis: <span class="assembly-date"><apa-date-picker v-model="mountTo[index]" :conf="dateConfig" :placeholder="$t('global.input_select_placeholder')" /></span>
                            </td>
                            <td>
                                <span v-if="checkDate(unmountFrom[index])" class="error">Bitte Datum auswählen.</span><br>
                                Von: <span class="assembly-date"><apa-date-picker v-model="unmountFrom[index]" :conf="dateConfig" :placeholder="$t('global.input_select_placeholder')" /></span><br>
                                <span v-if="checkDate(unmountTo[index])" class="error">Bitte Datum auswählen.</span><br>
                                Bis: <span class="assembly-date"><apa-date-picker v-model="unmountTo[index]" :conf="dateConfig" :placeholder="$t('global.input_select_placeholder')" /></span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <base-input label="APA Projektleiter" disabled v-model="plUser">
                    </base-input>
                </div>
                <div class="col">
                    <base-input label="APA Senior Projektleiter" disabled v-model="splUser">
                    </base-input>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <base-input label="APA Projektbetreuer" disabled v-model="pbUser">
                    </base-input>
                </div>
                <div class="col">
                    <base-input label="APA Techniker" :disabled="setDisabled" v-model="technician">
                    </base-input>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <base-input label="APA Operations" :disabled="setDisabled" v-model="operations">
                    </base-input>
                </div>
                <div class="col">
                    <base-input label="APA Grafik" :disabled="setDisabled" v-model="graphics">
                    </base-input>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <base-input label="Dienstleister" :disabled="setDisabled" v-model="serviceWorker">
                    </base-input>
                </div>
                <div class="col">
                    <base-input label="Teamleiter DL" :disabled="setDisabled" v-model="teamLeaderDl">
                    </base-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <base-input label="Mitarbeiteranzahl DL in PAX" :disabled="setDisabled" v-model="employeeCountDl">
                    </base-input>
                </div>
                <div class="col-md-6">
                    <base-input label="Anzahl Manntage" :disabled="setDisabled" v-model="workerDays">
                    </base-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <base-input label="Logistik / Spedition" :disabled="setDisabled" v-model="logistics">
                    </base-input>
                </div>
                <div class="col-md-12">
                    <base-input label="Arbeitshilfen" disabled v-model="tools">
                    </base-input>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <base-input label="Anreise Team">
                    <el-input
                        type="textarea"
                        rows="3"
                        placeholder=""
                        v-model="arrivalTeam"
                        :disabled="setDisabled"
                    ></el-input>
                    </base-input>
                </div>
                <div class="col">
                    <base-input label="Abladen & Aufladen vor Ort">
                    <el-input
                        type="textarea"
                        rows="3"
                        placeholder=""
                        v-model="unloadLoad"
                        :disabled="setDisabled"
                    ></el-input>
                    </base-input>
                </div>
                <div class="col">
                    <base-input label="Bürofläche">
                    <el-input
                        type="textarea"
                        rows="3"
                        placeholder=""
                        v-model="officeArea"
                        :disabled="setDisabled"
                    ></el-input>
                    </base-input>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <base-input label="Lagerfläche">
                    <el-input
                        type="textarea"
                        rows="3"
                        placeholder=""
                        v-model="storageArea"
                        :disabled="setDisabled"
                    ></el-input>
                    </base-input>
                </div>
                <div class="col">
                    <base-input label="Ansprechpartner Kunde">
                    <el-input
                        type="textarea"
                        rows="3"
                        placeholder=""
                        v-model="contactCustomer"
                        :disabled="setDisabled"
                    ></el-input>
                    </base-input>
                </div>
                <div class="col">
                    <base-input label="Ansprechpartner Location">
                    <el-input
                        type="textarea"
                        rows="3"
                        placeholder=""
                        v-model="contactLocation"
                        :disabled="setDisabled"
                    ></el-input>
                    </base-input>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <base-input label="Unterbringung">
                    <el-input
                        type="textarea"
                        rows="3"
                        placeholder=""
                        v-model="housing"
                        :disabled="setDisabled"
                    ></el-input>
                    </base-input>
                </div>
                <div class="col">
                    <base-input label="Fuhrparkplanung">
                    <el-input
                        type="textarea"
                        rows="3"
                        placeholder=""
                        v-model="fleetPlanning"
                        :disabled="setDisabled"
                    ></el-input>
                    </base-input>
                </div>
                <div class="col">
                    <base-input label="Site-Events">
                    <el-input
                        type="textarea"
                        rows="3"
                        placeholder=""
                        v-model="siteEvents"
                        :disabled="setDisabled"
                    ></el-input>
                    </base-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <base-input label="Produktionen">
                    <span class="small">Optimierungsvorschläge, generelle Anmerkungen zu Produktionen, Fehler</span>
                    <el-input
                        type="textarea"
                        rows="3"
                        placeholder=""
                        v-model="productions"
                        :disabled="setDisabled"
                    ></el-input>
                    </base-input>
                </div>
                <div class="col-md-12">
                    <base-input label="Logistische Planung">
                    <span class="small">Daten, Ladevolumen, Arbeitshilfen, Fuhrpark, etc. ...</span>
                    <el-input
                        type="textarea"
                        rows="3"
                        placeholder=""
                        v-model="technicLogistics"
                        :disabled="setDisabled"
                    ></el-input>
                    </base-input>
                </div>
                <div class="col-md-12">
                    <base-input label="Feedback Logistik">
                    <span class="small">Vollständigkeit der Materialien, Verpackung, generelle Anmerkungen</span>
                    <el-input
                        type="textarea"
                        rows="3"
                        placeholder=""
                        v-model="feedbackLogistics"
                        :disabled="setDisabled"
                    ></el-input>
                    </base-input>
                </div>
                <div class="col-md-12">
                    <base-input label="Feedback Grafik">
                    <el-input
                        type="textarea"
                        rows="3"
                        placeholder=""
                        v-model="feedbackGraphics"
                        :disabled="setDisabled"
                    ></el-input>
                    </base-input>
                </div>
                <div class="col-md-12">
                    <base-input label="Feedback Dienstleister">
                    <el-input
                        type="textarea"
                        rows="3"
                        placeholder=""
                        v-model="feedbackServiceWorker"
                        :disabled="setDisabled"
                    ></el-input>
                    </base-input>
                </div>
                <div class="col-md-12">
                    <base-input label="Entstandene Schäden">
                    <el-input
                        type="textarea"
                        rows="3"
                        placeholder=""
                        v-model="damages"
                        :disabled="setDisabled"
                    ></el-input>
                    </base-input>
                </div>
                <div class="col-md-12">
                    <base-input label="Entstandene Zusatzkosten / -arbeiten">
                    <el-input
                        type="textarea"
                        rows="3"
                        placeholder=""
                        v-model="additionalCosts"
                        :disabled="setDisabled"
                    ></el-input>
                    </base-input>
                </div>
                <div class="col-md-12">
                    <base-input label="Besondere Hinweise für die nächste Umsetzung vor Ort">
                    <el-input
                        type="textarea"
                        rows="3"
                        placeholder=""
                        v-model="generalNotes"
                        :disabled="setDisabled"
                    ></el-input>
                    </base-input>
                </div>
                <div class="col-md-12">
                    <base-input label="Bilderpfad" :disabled="setDisabled" v-model="picturePath">
                    </base-input>
                </div>
                <div class="col-md-12">
                    <base-input label="Protokoll erstellt von" :disabled="setDisabled" v-model="createdBy">
                    </base-input>
                </div>
                <div class="col-md-12">
                    <base-input label="Ort, Datum" :disabled="setDisabled" v-model="cityDate">
                    </base-input>
                </div>
            </div>
        </div>
        <template slot="footer">
            <base-button type="link" @click="onClose()">{{ $t('message.close') }}</base-button>
            <base-button type="primary" class="ml-auto" @click="onSave(true)">{{ $t('message.export') }}</base-button> 
            <base-button type="primary" class="ml-auto" @click="onSave()" :disabled="setDisabled">{{ $t('message.save') }}</base-button> 
        </template>
    </modal>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import api from "@/util/api.js";
import moment from 'moment';
import download from '@/util/download';
import Modal from '@/components/Modal';
import ApaDatePicker from "@/components/ApaInputs/ApaDatePicker";

export default {
    name: 'project-form',
    components: {
        Modal,
        ApaDatePicker
    },
    props: {
        show: {
            type: Boolean
        }
    },
    data() {
        return {
            savedData: {},
            projectLocations: [],
            isLoading: false,
            formDate: null,
            orderNumbers: null,
            mountFrom: [],
            mountTo: [],
            unmountFrom: [],
            unmountTo: [],
            teamMembers: null,
            plUser: null,
            splUser: null,
            pbUser: null,
            costAccount: null,
            technician: null,
            operations: null,
            graphics: null,
            serviceWorker: null,
            teamLeaderDl: null,
            employeeCountDl: null,
            workerDays: null,
            logistics: null,
            tools: null,
            arrivalTeam: null,
            unloadLoad: null,
            officeArea: null,
            storageArea: null,
            contactCustomer: null,
            contactLocation: null,
            housing: null,
            fleetPlanning: null,
            siteEvents: null,
            generalNotes: null,
            productions: null,
            technicLogistics: null,
            feedbackLogistics: null,
            feedbackGraphics: null,
            feedbackServiceWorker: null,
            damages: null,
            additionalCosts: null,
            picturePath: null,
            createdBy: null,
            cityDate: null
        }
    },
    watch: {
        show() {
            if(this.show) {
                this.load();
            }
        }
    },
    computed: {
        ...mapGetters([
            'currentUser',
            'currentUserRights',
            'project',
            'project_orders',
            'projectAddresses',
            'internalPersonTopics',
            'locations',
            'positions'
        ]),
        dateConfig() {
            let config = {
                          allowInput: false,
                          dateFormat: "Y-m-d",
                          altInput: true,
                          altFormat: "d.m.Y",
                          clickOpens: false
                         }

            if(this.currentUserRights.includes('debriefing.project_form.canedit')) {
                config.allowInput = true;
                config.clickOpens = true;
            }

            return config;
        },
        setDisabled() {
            return this.currentUserRights.includes('debriefing.project_form.canedit') ? false : true;
        }
    },
    methods: {
        ...mapActions([
            'getProjectPersons'
        ]),
        load: async function() {

            if(this.projectLocations.length == 0) {
                this.locations.map(loc => {
                    this.projectLocations.push({
                                                name: loc.address.name,
                                                city: loc.address.city,
                                                from: moment(loc.date_from).format('DD.MM.YYYY'),
                                                to: moment(loc.date_to).format('DD.MM.YYYY')
                                                })
                })
            }

            console.log('projectLocations',this.projectLocations);

            this.orderNumbers = this.project_orders.map(no => no.order_no).join(', ');

            this.savedData = await api("findDebriefings")
                .and("project_id", this.project.id)
                .run();

            console.log('saved Data', this.savedData)

            const tools = await api("findProject_tools")
                .and("project_id", this.project.id)
                .run();

            this.tools = tools.map((tool) => {
                const addr = this.projectAddresses.find(addr => addr.address_id === tool.address_id);
                tool.address = addr.address;
                tool.positions = this.positions.filter((item) => item.project_tool_id === tool.id);
                return tool.name;
            }).join(',');

            const tempInternals = await this.getProjectPersons(this.project.id);

            this.plUser = tempInternals
                .filter(pp => pp.topic == 'PL')
                .map(u => u.user.name)
                .join(',')

            this.splUser = tempInternals
                .filter(pp => pp.topic == 'SPL')
                .map(u => u.user.name)
                .join(',')

            this.pbUser = tempInternals
                .filter(pp => pp.topic == 'PROJEKTB')
                .map(u => u.user.name)
                .join(',')
                
            this.isLoading = false;

            this.assignSavedData();
        },
        assignSavedData() {
            if(this.savedData.length > 0) {
                const saved = JSON.parse(this.savedData[0].formdata_project);
                console.log('saved project data', saved)

                this.formDate = saved.date;
                this.costAccount = saved.costAccount;
                this.projectLocations = this.projectLocations ?? saved.locations;
                this.mountFrom = saved.mount_from ?? [];
                this.mountTo = saved.mount_to ?? [];
                this.unmountFrom = saved.unmount_from ?? [];
                this.unmountTo = saved.unmount_to ?? [];
                this.technician = saved.technician;
                this.operations = saved.operations;
                this.graphics = saved.graphics;
                this.arrivalTeam = saved.arrivalTeam;
                this.serviceWorker = saved.dl;
                this.teamLeaderDl = saved.leaderDl;
                this.employeeCountDl = saved.employeePax;
                this.workerDays = saved.employeeNo;
                this.logistics = saved.logistics;
                this.unloadLoad = saved.unloadLoad;
                this.officeArea = saved.officeArea;
                this.storageArea = saved.storageArea;
                this.contactCustomer = saved.contactCustomer;
                this.contactLocation = saved.contactLocation;
                this.housing = saved.housing;
                this.fleetPlanning = saved.fleetPlanning;
                this.siteEvents = saved.siteEvents;
                this.generalNotes = saved.notes;
                this.productions = saved.productions;
                this.technicLogistics = saved.techLog;
                this.feedbackLogistics = saved.feedbackLogistics;
                this.feedbackGraphics = saved.feedbackGraphics;
                this.feedbackServiceWorker = saved.swFeedback;
                this.damages = saved.damages;
                this.additionalCosts = saved.costs;
                this.picturePath = saved.picturePath;
                this.createdBy = saved.createdBy;
                this.cityDate = saved.cityDate;
            }
        },
        onSave(exportForm = false) {
            const savedLocations = [];
            
            for(let i=0; i<this.projectLocations.length; i++) {
                if (this.$refs.locationName[i]) {
                    const row = {
                        name: this.$refs.locationName[i].innerText,
                        city: this.$refs.locationCity[i].innerText,
                        from: this.$refs.eventStart[i].innerText,
                        to: this.$refs.eventEnd[i].innerText,
                    }
                    savedLocations.push(row);
                }
            }
            
            console.log('saved locations', savedLocations)

            const currentData = {
                projectName: this.project.name,
                date: this.formDate,
                orderNumbers: this.orderNumbers,
                costAccount: this.costAccount,
                ptNumber: this.project.pt_number,
                locations: savedLocations,
                mount_from: this.mountFrom,
                mount_to: this.mountTo,
                unmount_from: this.unmountFrom,
                unmount_to: this.unmountTo,
                pl: this.plUser,
                pb: this.pbUser,
                spl: this.splUser,
                technician: this.technician ?? '-',
                operations: this.operations ?? '-',
                graphics: this.graphics ?? '-',
                arrivalTeam: this.arrivalTeam ?? '-',
                dl: this.serviceWorker ?? '-',
                leaderDl: this.teamLeaderDl ?? '-',
                employeePax: this.employeeCountDl ?? '-',
                employeeNo: this.workerDays ?? '-',
                logistics: this.logistics ?? '-',
                unloadLoad: this.unloadLoad ?? '-',
                officeArea: this.officeArea ?? '-',
                storageArea: this.storageArea ?? '-',
                contactCustomer: this.contactCustomer ?? '-',
                contactLocation: this.contactLocation ?? '-',
                housing: this.housing ?? '-',
                fleetPlanning: this.fleetPlanning ?? '-',
                siteEvents: this.siteEvents ?? '-',
                tools: this.tools ?? '-',
                notes: this.generalNotes ?? '-',
                productions: this.productions ?? '-',
                techLog: this.technicLogistics ?? '-',
                feedbackLogistics: this.feedbackLogistics ?? '-',
                feedbackGraphics: this.feedbackGraphics ?? '-',
                swFeedback: this.feedbackServiceWorker ?? '-',
                damages: this.damages ?? '-',
                costs: this.additionalCosts ?? '-',
                picturePath: this.picturePath ?? '-',
                createdBy: this.createdBy ?? '-',
                cityDate: this.cityDate ?? '-'
            }
            console.log('saved form', currentData)

            const savedId = this.savedData ? this.savedData.map(i => i.id)[0] : null;
            const formData = {project_id: this.project.id, formdata_project: JSON.stringify(currentData)};

            const done = savedId
                        ? this.$store.dispatch('updateDebriefing', {id: savedId, data: formData})
                        : this.$store.dispatch('createDebriefing', {data: formData});

            done
            .then(() => {
                if(exportForm) {
                    this.report('de');
				    this.isLoading = false;
                }
            })
			.then(() => this.onClose())
            
        },
		onClose(state) {
			this.$emit('update:show', state);
		},
		async report(lang) {
            try{
				this.isLoading = true;
                const url = 'pdf/project/debriefing/'+this.project.id+'/project'
                + '?lang=' + lang

                console.log('url stringify', url)

                this.$notify({
                    verticalAlign: "top",
                    horizontalAlign: "right",
                    message: this.$t('message.export_start'),
                    type: "info",
                    timeout: 6000
                });

                await download(url, this.project.name + "-Debriefing-Projekt.pdf")
            } catch (e) {
				this.isLoading = false;
				this.$notify({
					verticalAlign: "top",
					horizontalAlign: "right",
					message: this.$t('message.export_pdf_error') + e.body.message,
					timeout: 5000,
					type: "error"
				});
			}
		},
        getSavedData() {
            this.savedData = this.loadDebriefingForProject(this.project.id);
        },
        checkDate(date) {
            if(typeof date != "string") {
                return true;
            }
        }
    },
    mounted() {
    }
}
</script>
<style>
@media (min-width: 576px) {
    .debriefing .modal-dialog {
        max-width: 1300px;
    }
}
.debriefing .modal-body {
    max-height: 75vh;
    overflow-y: auto;
}
.locations-table {
    margin-bottom: 25px;
}
.locations-table th, .locations-table td {
    border: 1px solid #d1d5da;
    padding: 5px;
}
.locations-table th {
    background-color: #f6f8fa;
}
.locations-table td {
    font-size: 0.875rem;
}
.assembly-date {
    display: inline-block;
    width: 100px;
    margin-bottom: 0.25rem;
}
.el-input.is-disabled .el-input__inner,
.el-textarea.is-disabled .el-textarea__inner {
    background-color: #e1e4e8;
    color: #606266;
}
.el-radio__input.is-disabled + span.el-radio__label {
    color: #606266;
}
.el-radio__input.is-disabled.is-checked .el-radio__inner {
    background-color: #e1e4e8;
    border-color: #606266;
}
.error {
    font-size: 10px;
    color: red;
}
</style>