<template >
    <modal @update:show="onClose" :show="show" class="debriefing">
        <h6 slot="header" class="modal-title">Erweitertes Debriefing Dienstleister</h6>
        <div v-loading="isLoading">
            <div class="row">
                <div class="col-md-9">
                    <base-input label="Event" disabled v-model="project.name">
                    </base-input>
                </div>
                <div class="col-md-3">
                    <base-input :label="$t('message.date')">
                        <apa-date-picker v-model="formDate" :conf="dateConfig" :placeholder="$t('global.input_select_placeholder')" />
                    </base-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <table class="locations-table" width="100%">
                        <tr>
                            <th class="form-control-label">Location</th>
                            <th class="form-control-label">Ort</th>
                        </tr>
                        <tr v-for="location in locations" :key="location.id">
                            <td>
                                <span ref="locationName">{{ location.address.name }}</span>
                            </td>
                            <td>
                                <span ref="locationCity">{{ location.address.city }}</span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <base-input label="Dienstleister" :disabled="setDisabled" v-model="serviceWorker">
                    </base-input>
                </div>
                <div class="col-md-12">
                    <base-input label="Teamleiter DL" :disabled="setDisabled" v-model="teamLeaderDl">
                    </base-input>
                </div>
                <div class="col-md-6">
                    <base-input label="Mitarbeiteranzahl DL in PAX" :disabled="setDisabled" v-model="employeeCountDl">
                    </base-input>
                </div>
                <div class="col-md-12">
                    <base-input label="APA Techniker (wenn vor Ort)" :disabled="setDisabled" v-model="technician">
                    </base-input>
                </div>
                <div class="col-md-12">
                    <div class="form-control-label">
                        Einsatzzeitraum
                    </div>
                    {{$t('message.from')}} <apa-date-picker class="col-4 d-inline-block m-2" v-model="operatingTime_from" :conf="dateConfig" :placeholder="$t('global.input_select_placeholder')" />
                    {{$t('message.until')}} <apa-date-picker class="col-4 d-inline-block m-2" v-model="operatingTime_to" :conf="dateConfig" :placeholder="$t('global.input_select_placeholder')" />
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h4 class="text-primary mt-2">Allgemeines</h4>
                </div>
                <div class="col-md-12">
                    <base-input label="War das Briefing vollständig?">
                        <el-radio v-model="briefingComplete" :label="1" :disabled="setDisabled">Ja</el-radio>
                        <el-radio v-model="briefingComplete" :label="0" :disabled="setDisabled">Nein</el-radio>
                    </base-input>
                </div>
                <div class="col-md-12">
                    <base-input label="Wenn Nein, was hat gefehlt bzw. was war ungenau?">
                    <el-input
                        type="textarea"
                        rows="3"
                        placeholder=""
                        v-model="briefingComplete_no"
                        :disabled="setDisabled"
                    ></el-input>
                    </base-input>
                </div>
                <div class="col-md-12">
                    <base-input label="War das Brandinghandbuch vollständig?">
                        <el-radio v-model="brandingComplete" :label="1" :disabled="setDisabled">Ja</el-radio>
                        <el-radio v-model="brandingComplete" :label="0" :disabled="setDisabled">Nein</el-radio>
                    </base-input>
                </div>
                <div class="col-md-12">
                    <base-input label="Wenn Nein, was war falsch bzw. was war ungenau?">
                    <el-input
                        type="textarea"
                        rows="3"
                        placeholder=""
                        v-model="brandingComplete_no"
                        :disabled="setDisabled"
                    ></el-input>
                    </base-input>
                </div>
                <div class="col-md-12">
                    <base-input label="War die Ausstattung (Werkzeuge, Hilfsmittel und Verbrauchsmaterialien) ausreichend?">
                        <el-radio v-model="equipmentComplete" :label="1" :disabled="setDisabled">Ja</el-radio>
                        <el-radio v-model="equipmentComplete" :label="0" :disabled="setDisabled">Nein</el-radio>
                    </base-input>
                </div>
                <div class="col-md-12">
                    <base-input label="Wenn Nein, was hat gefehlt?">
                        <el-input
                            type="textarea"
                            rows="3"
                            placeholder=""
                            v-model="equipmentComplete_no"
                            :disabled="setDisabled"
                        ></el-input>
                    </base-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h4 class="text-primary">Produktionen / Lagerware</h4>
                </div>
                <div class="col-md-12">
                    <base-input label="Waren die Platten passgenau gefertigt?">
                        <el-radio v-model="materialExactly_ok" :label="1" :disabled="setDisabled">Ja</el-radio>
                        <el-radio v-model="materialExactly_ok" :label="0" :disabled="setDisabled">Nein</el-radio>
                    </base-input>
                </div>
                <div class="col-md-12">
                    <base-input label="Waren die Steckkedertücher passgenau gefertigt?">
                        <el-radio v-model="materialPiping_ok" :label="1" :disabled="setDisabled">Ja</el-radio>
                        <el-radio v-model="materialPiping_ok" :label="0" :disabled="setDisabled">Nein</el-radio>
                    </base-input>
                </div>
                <div class="col-md-12 text-bold mb-3">
                    Welche Produktionen waren mangelhaft?
                </div>
                <div class="col-md-12">
                    <table class="productions-table" width="100%">
                        <tr>
                            <th class="text-primary text-center" style="width: 150px;">PT-Nr.</th>
                            <th class="text-primary text-center">Bezeichnung</th>
                            <th class="text-primary text-center" style="width: 250px;">Breite x Höhe</th>
                            <th class="text-primary text-center">Mangelbeschreibung</th>
                            <th class="text-center">
                                <el-tooltip :content="$t('message.add')" placement="top">
                                    <i class="fas fa-plus-circle text-primary"
                                    @click="addProduction"></i>
                                </el-tooltip>
                            </th>
                        </tr>
                        <tr v-for="(newProduction, counter) in productionsBad" :key="counter">
                            <td><el-input v-model="newProduction.pt_number" :disabled="setDisabled" /></td>
                            <td><el-input v-model="newProduction.name" :disabled="setDisabled" /></td>
                            <td><el-input v-model="newProduction.dimension" :disabled="setDisabled" /></td>
                            <td><el-input v-model="newProduction.description" :disabled="setDisabled" type="textarea" rows="2" /></td>
                            <td class="text-center">
                                <el-tooltip :content="$t('message.delete')" placement="top">
                                    <i class="fas fa-minus-circle"
                                    @click="deleteProduction(counter)"></i>
                                </el-tooltip>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="col-md-12">
                    <base-input label="Waren die Lagerwaren sauber und ordentlich verpackt?">
                        <el-radio v-model="stockClean_ok" :label="1" :disabled="setDisabled">Ja</el-radio>
                        <el-radio v-model="stockClean_ok" :label="0" :disabled="setDisabled">Nein</el-radio>
                    </base-input>
                </div>
                <div class="col-md-12">
                    <base-input label="War der Zustand der Lagerwaren in Ordnung?">
                        <el-radio v-model="stockCondition_ok" :label="1" :disabled="setDisabled">Ja</el-radio>
                        <el-radio v-model="stockCondition_ok" :label="0" :disabled="setDisabled">Nein</el-radio>
                    </base-input>
                </div>
                <div class="col-md-12">
                    <base-input label="Bemerkungen">
                        <el-input
                            type="textarea"
                            rows="3"
                            placeholder=""
                            v-model="productionsNotes"
                            :disabled="setDisabled"
                        ></el-input>
                    </base-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h4 class="text-primary">Entstandene Schäden</h4>
                </div>
                <div class="col-md-12">
                    <base-input label="Sind während dem Einsatz Schäden entstanden?">
                        <el-radio v-model="damagesOccured" :label="1" :disabled="setDisabled">Ja</el-radio>
                        <el-radio v-model="damagesOccured" :label="0" :disabled="setDisabled">Nein</el-radio>
                    </base-input>
                </div>
                <div class="col-md-12">
                    <base-input label="Wenn ja, wurde ein Schadensprotokoll ausgefüllt und der APA-Projektleiter hierüber informiert?">
                        <el-radio v-model="damagesProtocol" :label="1" :disabled="setDisabled">Ja</el-radio>
                        <el-radio v-model="damagesProtocol" :label="0" :disabled="setDisabled">Nein</el-radio>
                    </base-input>
                </div>
                <div class="col-md-12">
                    <base-input label="Bemerkungen">
                        <el-input
                            type="textarea"
                            rows="3"
                            placeholder=""
                            v-model="damagesNotes"
                            :disabled="setDisabled"
                        ></el-input>
                    </base-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <base-input label="Abschließende Bemerkungen">
                    <el-input
                        type="textarea"
                        rows="3"
                        placeholder=""
                        v-model="finalNotes"
                        :disabled="setDisabled"
                    ></el-input>
                    </base-input>
                </div>
                <div class="col-md-12">
                    <base-input label="Erstellt von" :disabled="setDisabled" v-model="createdBy">
                    </base-input>
                </div>
            </div>
        </div>
        <template slot="footer">
            <base-button type="link" @click="onClose()">{{ $t('message.close') }}</base-button>
            <base-button type="primary" class="ml-auto" @click="onSave(true)">{{ $t('message.export') }}</base-button> 
            <base-button type="primary" class="ml-auto" @click="onSave()" :disabled="setDisabled">{{ $t('message.save') }}</base-button> 
        </template>
    </modal>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import api from "@/util/api.js";
import download from '@/util/download';
import Modal from '@/components/Modal';
import ApaDatePicker from "@/components/ApaInputs/ApaDatePicker";

export default {
    name: 'service-form',
    components: {
        Modal,
        ApaDatePicker
    },
    props: {
        show: {
            type: Boolean
        }
    },
    data() {
        return {
            savedData: {},
            isLoading: false,
            formDate: null,
            technician: null,
            operatingTime_from: null,
            operatingTime_to: null,
            serviceWorker: null,
            teamLeaderDl: null,
            employeeCountDl: null,
            workerDays: null,
            briefingComplete: null,
            briefingComplete_no: null,
            brandingComplete: null,
            brandingComplete_no: null,
            equipmentComplete: null,
            equipmentComplete_no: null,
            materialExactly_ok: null,
            materialPiping_ok: null,
            stockClean_ok: null,
            stockCondition_ok: null,
            productionsBad: [],
            productionsNotes: null,
            damagesOccured: null,
            damagesProtocol: null,
            damagesNotes: null,
            finalNotes: null,
            createdBy: null
        }
    },
    watch: {
        show() {
            if(this.show) {
                this.load();
            }
        }
    },
    computed: {
        ...mapGetters([
            'currentUser',
            'currentUserRights',
            'project',
            'locations'
        ]),
        dateConfig() {
            let config = {
                          allowInput: false,
                          dateFormat: "Y-m-d",
                          altInput: true,
                          altFormat: "d.m.Y",
                          clickOpens: false
                         }

            if(this.currentUserRights.includes('debriefing.service_form.canedit')) {
                config.allowInput = true;
                config.clickOpens = true;
            }

            return config;
        },
        setDisabled() {
            return this.currentUserRights.includes('debriefing.service_form.canedit') ? false : true;
        }
    },
    methods: {
        load: async function() {
            this.savedData = await api("findDebriefings")
                .and("project_id", this.project.id)
                .run();

            this.isLoading = false;

            this.assignSavedData();
        },
        addProduction() {
            this.productionsBad.push({
                pt_number: '',
                name: '',
                dimension: '',
                description: ''
            })
        },
        deleteProduction(counter) {
            this.productionsBad.splice(counter,1);
        },
        assignSavedData() {
            if(this.savedData.length > 0) {
                const saved = JSON.parse(this.savedData[0].formdata_service);
                console.log('saved service data', saved)

                if(saved != null) {
                    this.formDate = saved.formDate;
                    this.technician = saved.technician;
                    this.operatingTime_from = saved.operatingTime_from;
                    this.operatingTime_to = saved.operatingTime_to;
                    this.serviceWorker = saved.serviceWorker;
                    this.teamLeaderDl = saved.teamLeaderDl;
                    this.employeeCountDl = saved.employeeCountDl;
                    this.workerDays = saved.workerDays;
                    this.briefingComplete = saved.briefingComplete;
                    this.briefingComplete_no = saved.briefingComplete_no;
                    this.brandingComplete = saved.brandingComplete;
                    this.brandingComplete_no = saved.brandingComplete_no;
                    this.equipmentComplete = saved.equipmentComplete;
                    this.equipmentComplete_no = saved.equipmentComplete_no;
                    this.materialExactly_ok = saved.materialExactly_ok;
                    this.materialPiping_ok = saved.materialPiping_ok;
                    this.stockClean_ok = saved.stockClean_ok;
                    this.stockCondition_ok = saved.stockCondition_ok;
                    this.productionsBad = saved.productionsBad;
                    this.productionsNotes = saved.productionsNotes;
                    this.damagesOccured = saved.damagesOccured;
                    this.damagesProtocol = saved.damagesProtocol;
                    this.damagesNotes = saved.damagesNotes;
                    this.finalNotes = saved.finalNotes;
                    this.createdBy = saved.createdBy;
                }
            }
        },
        onSave(exportForm = false) {
            const projectLocations = [];
            
            for(let i=0; i<this.locations.length; i++) {
                const locationsRow = {
                    name: this.$refs.locationName[i].innerText,
                    city: this.$refs.locationCity[i].innerText
                }
                projectLocations.push(locationsRow);
            }

            const currentData = {
                projectName: this.project.name,
                formDate: this.formDate,
                locations: projectLocations,
                technician: this.technician,
                operatingTime_from: this.operatingTime_from,
                operatingTime_to: this.operatingTime_to,
                serviceWorker: this.serviceWorker,
                teamLeaderDl: this.teamLeaderDl,
                employeeCountDl: this.employeeCountDl,
                workerDays: this.workerDays,
                briefingComplete: this.briefingComplete,
                briefingComplete_no: this.briefingComplete_no,
                brandingComplete: this.brandingComplete,
                brandingComplete_no: this.brandingComplete_no,
                equipmentComplete: this.equipmentComplete,
                equipmentComplete_no: this.equipmentComplete_no,
                materialExactly_ok: this.materialExactly_ok,
                materialPiping_ok: this.materialPiping_ok,
                stockClean_ok: this.stockClean_ok,
                stockCondition_ok: this.stockCondition_ok,
                productionsBad: this.productionsBad,
                productionsNotes: this.productionsNotes,
                damagesOccured: this.damagesOccured,
                damagesProtocol: this.damagesProtocol,
                damagesNotes: this.damagesNotes,
                finalNotes: this.finalNotes,
                createdBy: this.createdBy
            }
            console.log('saved service form', currentData)

            const savedId = this.savedData ? this.savedData.map(i => i.id)[0] : null;

            console.log('saved ID', savedId)
            console.log('formData', JSON.stringify(currentData))

            const formData = {project_id: this.project.id, formdata_service: JSON.stringify(currentData)};

            console.log('formData', formData)

            const done = savedId
                        ? this.$store.dispatch('updateDebriefing', {id: savedId, data: formData})
                        : this.$store.dispatch('createDebriefing', {data: formData});

            console.log('formData done', done)
            done
            .then(() => {
                if(exportForm) {
                    this.report('de');
				    this.isLoading = false;
                }
            })
			.then(() => this.onClose())
            
        },
		onClose(state) {
			this.$emit('update:show', state);
		},
		async report(lang) {
            try{
				this.isLoading = true;
                const url = 'pdf/project/debriefing/'+this.project.id+'/service'
                + '?lang=' + lang

                console.log('url stringify', url)

                this.$notify({
                    verticalAlign: "top",
                    horizontalAlign: "right",
                    message: this.$t('message.export_start'),
                    type: "info",
                    timeout: 6000
                });

                await download(url, this.project.name + "-Debriefing-Dienstleister.pdf")
            } catch (e) {
				this.isLoading = false;
				this.$notify({
					verticalAlign: "top",
					horizontalAlign: "right",
					message: this.$t('message.export_pdf_error') + e.body.message,
					timeout: 5000,
					type: "error"
				});
			}
		}
    },
    mounted() {
    }
}
</script>
<style>
.productions-table {
    margin-bottom: 25px;
}
.productions-table th, .productions-table td {
    border: 1px solid #d1d5da;
    padding: 5px;
}
.productions-table th {
    background-color: #f6f8fa;
}
.productions-table td {
    font-size: 0.875rem;
}
</style>