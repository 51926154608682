<template>
  <div class="card" v-loading="isLoading">
    <div class="card-header">
      <div class="d-flex">
        <h3 class="m-0 pr-4 flex-grow-1">{{ $t('message.team') }}</h3>
        <el-tooltip :content="$t('message.new')" placement="top">
          <base-button type="primary" icon round size="sm" @click="onCreate" :disabled="project.state === 'ARCHIVED'">
            <i class="fas fa-plus p-0"></i>
          </base-button>
        </el-tooltip>
      </div>
    </div>

    <el-table
      class="table-responsive table-compact"
      header-row-class-name="thead-light"
      row-key="id"
      :data="teamMembers"
    >
      <el-table-column :label="$t('message.name')" prop="user.name" sortable />
      <el-table-column :label="$t('message.responsible')" prop="topic" sortable />
      <el-table-column :label="$t('message.einsatzorte')" sortable>
        <template v-slot="{row}">
          <span v-for="(address,i) in row.addresses" :key="i"><br v-if="i>0">{{address.address.name}}</span>
        </template>
      </el-table-column>

      <el-table-column label width="88">
        <template v-slot="{row}">
          <el-tooltip :content="$t('message.edit')" placement="top">
            <base-button type="link" size="sm" @click="onEdit(row)" :disabled="project.state === 'ARCHIVED'">
              <i class="fas fa-edit text-black"></i>
            </base-button>
          </el-tooltip>
          <el-tooltip :content="$t('message.delete')" placement="top">
            <base-button type="link" size="sm" @click="onDelete(row)" :disabled="project.state === 'ARCHIVED'">
              <i class="fas fa-trash text-black"></i>
            </base-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <team-form
      :project="project"
      :show.sync="showForm"
      :team-member="teamMember"
      v-if="teamMember"
      @changed="onChanged"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import api from "@/util/api.js";

import TeamForm from "./TeamForm";

export default {
  name: "team",
  components: {
    TeamForm
  },
  props: {
    project: {
      type: Object
    }
  },
  computed: {
    ...mapGetters([
      'internalPersonTopics'
    ])
  },
  data() {
    return {
      isLoading: true,
      teamMember: null,
      teamMembers: [],
      showForm: false
    };
  },
  methods: {
    ...mapActions(["getProjectPersons"]),
    load: async function() {
      const tempInternals = await this.getProjectPersons(this.project.id);
      const topics = this.internalPersonTopics
        .map(t => t.val)

      this.teamMembers = tempInternals
        .filter(pp => {
          return topics.includes(pp.topic)
        })

      this.isLoading = false;
    },
    onChanged() {
      this.isLoading = true;
      this.load();
    },
    onCreate() {
      this.showForm = true;
      this.teamMember = {
        addresses: [],
        user_id: null,
        topic: null,
        additional: ""
      };
    },
    onDelete: async function(teamMember) {
      try {
        await this.$confirm(
          this.$t('message.notificationTeamDelete'),
          this.$t('message.notificationTeamDeleted'),
          {
            confirmButtonText: this.$t("message.yes"),
            cancelButtonText: this.$t("message.no"),
            type: "warning"
          }
        );
      } catch (error) {
        return;
      }

      this.isLoading = true;
      if (teamMember.addresses) {
        teamMember.addresses.forEach(async address => {
          await this.$store.dispatch("deletePerson_address", { id: address.id });
        })
      }

      await this.$store.dispatch("deleteProject_person", { id: teamMember.id });
      await api("findPerson_addresses")
              .and("project_id", this.project.id)
              .with("address")
              .with("user", { one: "users", this: "user_id" })
              .with("hotel", { one: "address", this: "hotel_id" })
              .run();

      this.isLoading = false;

      this.load();
    },
    onEdit(teamMember) {
      this.teamMember = teamMember;
      this.showForm = true;
    }
  },
  mounted() {
    this.load();
  }
};
</script>
