<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <!-- <h6 class="h2 d-inline-block mb-0">Projekte</h6> -->
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <!-- <base-button size="sm" type="primary" @click="$router.push({ path: '/admin/projects/add'})">
            <i class="ni ni-fat-add"></i>Neues Projekt
          </base-button>
           <base-button size="sm" type="primary" @click="$router.push({ path: '/admin/projects/add'})">
            <i class="ni ni-fat-add"></i>Neu aus Vorlage
          </base-button> -->
          <!-- <base-button size="sm" type="neutral">Filters</base-button> -->
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="border-0 card-header">
                <div class="row">
                    <div class="col-6">
                        <h3 class="mb-0">{{$t('message.overview')}}</h3>
                    </div>
                  </div>
            </div>

            <el-table class="table-responsive table-flush"
                      header-row-class-name="thead-light"
                      :data="menuItems">
                <el-table-column :label="$t('message.MenuItem')"
                                  prop="name"
                                  >
                    <template v-slot="{row}">
                      {{$t(row.caption)}}
                    </template>
                </el-table-column>
                <el-table-column align="right">
                    <template v-slot="{row}">
                      <base-button @click="$router.push({ path: row.path })">{{$t('message.open')}}</base-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
            <div class="col">
                    <base-button type="primary" size="sm" @click="updatePics" :loading="loading">
                        PT Update
                    </base-button >
            </div>
        </div>
      </div>
      <!-- <inline-actions-table></inline-actions-table>
      <striped-table></striped-table>
      <checkbox-table></checkbox-table>
      <checkbox-colored-table></checkbox-colored-table>
      <translucent-table></translucent-table>
      <dark-table></dark-table> -->
    </div>
  </div>
  
</template>

<script>
  import Vue from 'vue'

  export default {
    name: 'adminView',
    components: {
   
    },
    props: ['data', 'employees'],
    data() {
      return {
        menuItems: [
          {
            caption: 'message.user',
            path: '/admin/users'
          },
          {
            caption: 'message.vehicles',
            path: '/admin/vehicles'
          },
          {
            caption: 'message.articles',
            path: '/admin/articles'
          },
          {
            caption: 'message.hotels',
            path: '/admin/hotels'
          },
          {
            caption: 'message.montage',
            path: '/admin/montage'
          },
          {
            caption: 'message.artkat',
            path: '/admin/artkat'
          },
          {
            caption: 'message.teams',
            path: '/admin/teams'
          },
        ],
        loading: false
      }
    },
    methods: {
      filesChange(files) {
        this.inputs.file = files
      },

      async updatePics() {
        try {
          this.loading = true;
          await Vue.http['get']('pt/picsupdate', {});
          this.loading = false;
          this.$notify({
            verticalAlign: 'top',
            horizontalAlign: 'right',
            message: 'Updates wurden eingelesen.',
            type: 'success'
          })
        } catch(response) {
          this.$notify({
            verticalAlign: 'top',
            horizontalAlign: 'right',
            message: 'Fehler beim Update aus PT: <br>' + response.body.message,
            type: 'error'
          });
          this.loading = false;
        }
      }
    }
  }
</script>
<style>
.texto_grande {
    font-size: 1.0rem;

} 
#icone_grande {
    font-size: 8rem;
} 
</style>
