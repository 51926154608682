<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <!-- <h6 class="h2 d-inline-block mb-0">{{$route.name}}</h6> -->
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="primary" @click="save">Speichern</base-button>
          <base-button size="sm" type="neutral" @click="$router.push({ path: '/admin/hotels'})">Abbrechen</base-button>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">Hotel</h3>
        </div>
        <!-- Card body -->
        <div class="card-body">

          <div class="row">
            <div class="col-md-6">
              <base-input required label="Name" placeholder="Name" v-model="item.name"></base-input>
            </div>
            <div class="col-md-6">
              <base-input required label="Bewertung">
                <el-rate v-model="item.rating"></el-rate>
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <base-input required label="Straße" placeholder="Straße" v-model="item.street"></base-input>
            </div>
            <div class="col-md-6">
              <base-input required label="Hausnummer" placeholder="Hausnummer" v-model="item.no"></base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <base-input required label="Plz" placeholder="Plz" v-model="item.zip"></base-input>
            </div>
            <div class="col-md-4">
              <base-input required label="Ort" placeholder="Ort" v-model="item.city"></base-input>
            </div>
            <div class="col-md-4">
              <base-input required label="Land " placeholder="Land" v-model="item.country"></base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <base-input required type="tel" label="Telefon" placeholder="Telefon" v-model="item.phone"></base-input>
            </div>
            <div class="col-md-4">
              <base-input required type="tel" label="Mobil" placeholder="Mobil" v-model="item.mobile"></base-input>
            </div>
            <div class="col-md-4">
              <base-input required type="email" label="E-Mail" placeholder="E-Mail" v-model="item.email"></base-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'RolesView',
    components: {
   
    },
    props: ['data', 'employees'],
    computed: {
      ...mapGetters([
//        'vehicles'
      ])
    },
    mounted () {
      if (!this.getId()) {
        this.item = {}
      } else {
        this.$store.dispatch('getAddress', {id: this.getId()}).then(item=>{
          this.item=Object.assign({}, item)
        })
      }
    },
    data() {
      return {
        item: {}
      }
    },
    methods: {
      getId() {
        return this.$route.params.id=='new' ? null : this.$route.params.id;
      },
      save() {
        this.item.type = 'HOTEL'
        const done = this.getId()
          ? this.$store.dispatch('updateAddress', {id: this.getId(), data: this.item})
          : this.$store.dispatch('createAddress', {data: this.item});
        done.then( this.$router.push({ path: '/admin/hotels'}) )
      },
      formatTooltip(value) {
        return ['Nicht bewertet', 'Schlecht', 'Schlecht', 'Normal', 'Empfohlen', 'Empfohlen'][value]
      }
    }
  }
</script>