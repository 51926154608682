<template>
  <div>
    <base-header class="pb-6 content__title content__title--calendar">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
        <div class="col-lg-6 mt-3 mt-lg-0 text-lg-right">
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <!-- Fullcalendar -->
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
              <h5 class="h3 mb-0">{{$t('message.archive')}}</h5>
            </div>
            <!-- Card body -->
            <div class="card-body p-0">
              <!-- List group -->
              <div class="list-group list-group-flush">
                <news-item class="clickable" v-for="item in news" :item="item" :key="item.id" ></news-item>
                <div class="d-flex w-100 justify-content-between" v-if="news.length === 0">
                  Keine Einträge
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>

  import { mapGetters } from 'vuex'
  import Vue from "vue"
  import NewsItem from '@/views/News/NewsItem'

  const hidden = ['id', 'functions', 'assembly', 'bhb_annotation']

  export default {
    name: 'archive',
    components: {
      NewsItem
    },
    computed: {
      ...mapGetters([
      ]),
      news() {
        return this.log.map(log => ({
          id: log.id,
          username: log.user.name,
          date:log.created_at,
          title: this.$t('log.type.'+log.type)+" "+this.$t('log.operation.'+log.operation),
          text: this.textFor(log),
        }))
      }
    },
    data() {
      return {
        log: []
      }
    },
    methods: {
      textFor(log) {
        const result = []
        const content = JSON.parse(log.content)
        Object.keys(content).forEach(key => {
            if (hidden.indexOf(key)==-1 && !key.endsWith('_id')) {
              const value = content[key]  || '-'
              result.push(key + ': ' + value)
            }
        })
        return result.join(', ')
      }
    },
    mounted() {
      const id = this.$route.params.id
      Vue.http.post(`log/query`, {
        or: {
          'content->project_id': id,
          'and': {
            type: 'project',
            item_id:id
          }
        },
        with: {
          user: {one: 'users', this: 'user_id'}
        },
        order: {
          created_at: 'desc'
        }
      })
        .then(res => res.json())
        .then(log => this.log = log)
    }
  };
</script>
