<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <!-- <h6 class="h2 d-inline-block mb-0">Projekte</h6> -->
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <!-- <base-button size="sm" type="primary" @click="$router.push({ path: '/admin/projects/add'})">
            <i class="ni ni-fat-add"></i>Neues Projekt
          </base-button>
           <base-button size="sm" type="primary" @click="$router.push({ path: '/admin/projects/add'})">
            <i class="ni ni-fat-add"></i>Neu aus Vorlage
          </base-button> -->
          <!-- <base-button size="sm" type="neutral">Filters</base-button> -->
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="border-0 card-header">
                <div class="row">
                    <div class="col-6">
                        <h3 class="mb-0"></h3>
                    </div>
                    <div class="col-6 text-right">
                        <base-button type="primary" size="sm" @click="syncArticles" :loading="loading">
                            {{$t('message.syncArticle')}}
                        </base-button >
                        <base-button type="primary" size="sm" @click="$router.push({ path: '/admin/articles/form/new'})">
                            {{$t('message.new')}}
                        </base-button >
                        <!-- <base-split-button type="primary" size="sm" text="Neues Projekt">
                            <a class="dropdown-item">Neu aus Vorlage</a>
                        </base-split-button> -->
                    </div>
                </div>
            </div>

            <el-table class="table-responsive table-flush"
                      header-row-class-name="thead-light"
                      :data="variants">
                <el-table-column label="Artikelnummer"
                                prop="ItemNo"
                                sortable>
                </el-table-column>
                <el-table-column label="Variante"
                                prop="Code"
                                sortable>
                </el-table-column>
                <el-table-column label="Beschreibung"
                                prop="Description"
                                sortable>
                </el-table-column>
                <el-table-column label="Aktiv"
                                prop="active"
                                sortable>
                  <template v-slot="{row}">
                    {{ row.active ? 'Ja': 'Nein' }}
                  </template>
                </el-table-column>
                <el-table-column align="right" width="90">
                    <template v-slot="{row}">
                        <base-button type="link" size="sm" @click="$router.push({ path: '/admin/articles/form/'+row.id})">
                          <span class="btn-inner--icon">
                            <i class="fas fa-edit text-black"></i>
                          </span>
                        </base-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import { mapGetters } from 'vuex'

  export default {
    name: 'Articles',
    components: {

    },
    mounted () {
      this.reload()
    },
    computed: {
      ...mapGetters([
        'variants'
      ])
    },
    data() {
      return {
        addUser: false,
        vehicle: null,
        employee: null,
        project: {
          date: null
        },
        modals: {
          addHotel: false,
          deleteHotel: false
        },
        dateTimePicker: {
          enableTime: true,
          dateFormat: 'd-m-Y H:i'
        },
        loading: false
      }
    },
    methods: {
      reload() {
        return this.$store.dispatch('findVariants', { data: {} })
      },
      async syncArticles() {
        try {
          this.loading = true;
          await Vue.http['get']('dyn/article/sync', {});
          await this.reload();
          this.loading = false;
          this.$notify({
            verticalAlign: 'top',
            horizontalAlign: 'right',
            message: 'Artikeldaten wurden neu eingelesen.',
            type: 'success'
          })
        } catch(response) {
          this.$notify({
            verticalAlign: 'top',
            horizontalAlign: 'right',
            message: 'Fehler beim Dynamics-Sync: ' + response.statusText,
            type: 'error'
          });
          this.loading = false
        }
      }
    }
  }
</script>
<style>
.texto_grande {
    font-size: 1.0rem;

}
#icone_grande {
    font-size: 8rem;
}
</style>
