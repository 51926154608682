<template>
    <div class="card">
        <!-- Card header -->
        <div class="card-header">
            <div class="row">
                <div class="col-6">
                    <h3 class="mb-0">{{label}}
                    </h3>
                </div>
                <div class="col-6 text-right">
                    <el-tooltip content="Neu" placement="top">
                        <base-button type="primary" icon size="sm" @click="newItem()">
                            <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
                        </base-button>
                    </el-tooltip>
                </div>
            </div>
        </div>

        <el-table class="table-responsive table-flush"
                  header-row-class-name="thead-light"
                  row-key="key"
                  :data="globalOptions.filter(o => o.type==type)">

            <el-table-column label="Text"
                             prop="name"
                             sortable>
            </el-table-column>
            <el-table-column label="Text Englisch"
                             prop="name_en"
                             sortable>
            </el-table-column>
            <el-table-column label="" align="right">
                <template v-slot="{row}">
                    <el-tooltip content="Bearbeiten" placement="top">
                        <base-button type="link" size="sm" @click="editItem(row)">
                            <span class="btn-inner--icon"><i class="fas fa-edit text-black"></i></span>
                        </base-button>
                    </el-tooltip>
                    <el-tooltip content="Löschen" placement="top">

                        <base-button type="link" size="sm" @click="askDelete(row)">
                            <span class="btn-inner--icon"><i class="fas fa-trash text-black"></i></span>
                        </base-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>

        <modal :show.sync="modals.addItem">
            <h6 slot="header" class="modal-title">{{label}} hinzufügen</h6>

            <base-input required :maxLength="65" label="Text" v-model="item.name"></base-input>
            <base-input required :maxLength="65" label="Text Englisch" v-model="item.name_en"></base-input>

            <template slot="footer">
                <base-button type="primary" @click="save()">Speichern</base-button>
                <base-button type="link" class="ml-auto" @click="modals.addItem = false">Abbrechen</base-button>
            </template>
        </modal>

        <modal :show.sync="modals.deleteItem">
            <h6 slot="header" class="modal-title">{{label}} löschen</h6>
            <p>Wollen Sie diese {{label}} wirklich löschen?</p>
            <template slot="footer">
                <base-button type="primary" @click="deleteItem()">Ja</base-button>
                <base-button type="link" class="ml-auto" @click="modals.deleteItem = false">Nein</base-button>
            </template>
        </modal>
    </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'


  export default {
    name: 'options',
    components: {
    },
    props: ['type', 'label'],
    computed: {
      ...mapGetters(['globalOptions']),
    },
    data() {
      return {
        items: [],
        item: {},
        modals: {
          addItem: false,
          deleteItem: false
        },
      }
    },
    mounted() {
      this.reset()
    },
    methods: {
      ...mapActions([
        'loadGlobalOptions'
      ]),
      reset() {
        this.loadGlobalOptions()
      },
      newItem() {
        this.modals.addItem = true;
        this.item = {project_id: null, type:this.type }
      },
      editItem(item) {
        this.item = Object.assign({}, item);
        this.modals.addItem = true
      },
      askDelete(item) {
        this.modals.deleteItem = true;
        this.item = item;
      },
      deleteItem() {
        this.$store.dispatch('deleteOption', {id: this.item.id}).then(res => {
          this.item = {};
          this.reset();
          this.modals.deleteItem = false
        })
      },
      save() {
          const done = this.item.id
            ? this.$store.dispatch('updateOption', {id: this.item.id, data: this.item})
            : this.$store.dispatch('createOption', {data: this.item});
          done.then(() => {
            this.reset();
            this.modals.addItem = false
          })
      },
    }
  }
</script>
