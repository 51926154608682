<template>
  <div class="card" v-loading="isLoading">
    <div class="card-header">
      <div class="d-flex">
        <h3 class="m-0 pr-4 flex-grow-1">{{ $t('message.externals')}}</h3>
        <el-tooltip content="Neu" placement="top">
          <base-button type="primary" icon round size="sm" @click="onCreate" :disabled="project.state === 'ARCHIVED'">
            <i class="fas fa-plus p-0"></i>
          </base-button>
        </el-tooltip>
      </div>
    </div>

    <el-table
      class="table-responsive table-compact"
      header-row-class-name="thead-light"
      row-key="id"
      :data="externals">
      <el-table-column label="Name" prop="name" sortable>
        <template v-slot="{row}">{{row.user.name}}</template>
      </el-table-column>
       <el-table-column :label="$t('message.phone')" prop="phone" sortable>
        <template v-slot="{row}">{{row.user.phone}}</template>
      </el-table-column>
      <el-table-column :label="$t('message.responsible')" prop="role" sortable>
        <template v-slot="{row}">{{ getTopics(row)}}</template>
      </el-table-column>
      <el-table-column label width="88">
        <template v-slot="{row}">
          <el-tooltip :content="$t('message.edit')" placement="top">
            <base-button type="link" size="sm" @click="onEdit(row)" :disabled="project.state === 'ARCHIVED'">
              <i class="fas fa-edit text-black"></i>
            </base-button>
          </el-tooltip>
          <el-tooltip :content="$t('message.delete')" placement="top">
            <base-button type="link" size="sm" @click="onDelete(row)" :disabled="project.state === 'ARCHIVED'">
              <i class="fas fa-trash text-black"></i>
            </base-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <externals-form :project-id="project.id" :show.sync="showForm" :external="external" v-if="external" @changed="onChanged"></externals-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import ExternalsForm from "./ExternalsForm"

export default {
  name: "externals",
  components: {
    ExternalsForm
  },
  props: {
    project: {
      type: Object
    }
  },
  data() {
    return {
      isLoading: true,
      externals: [],
      external: null,
      showForm: false,

      roles: [
        {
          order: 1,
          name: "SPL"
        },
        {
          order: 2,
          name: "PL"
        },
        {
          order: 3,
          name: "Designer"
        },
        {
          order: 4,
          name: "Techniker"
        }
      ]
    };
  },
  computed: {
    ...mapGetters([
      'externalPersonTopics'
    ])
  },
  methods: {
    ...mapActions([
      'getProjectPersons'
    ]),
    getTopics(row) {
      // TODO: Does not work, check data structure
      if (!row.topic) {
        return "-";
      }

      return row.topic
        .split(',')
        .map(topic => this.externalPersonTopics.find(t => t.val === topic))
        .map(topic => topic.name)
        .join(', ')
    },
    load: async function() {
      const tempExternals = await this.getProjectPersons(this.project.id);
      const topics = this.externalPersonTopics
        .map(t => t.val);

      this.externals = tempExternals
        .filter(pp => {
          return pp.topic && pp.topic.split(',').some(topic => topics.includes(topic))
        });

      this.isLoading = false;
    },
    onChanged() {
      this.isLoading = true;
      this.load();
    },
    onCreate() {
      this.external = {
        user_id: null,
        topic: null,
        additional: "",
        project_id: this.project.id
      };

      this.showForm = true;
    },
    onDelete: async function(external) {
      try {
        await this.$confirm(
          this.$t('message.notificationContactDelete'),
          this.$t('message.notificationContactDeleted'),
          {
            confirmButtonText: this.$t("message.yes"),
            cancelButtonText: this.$t("message.no"),
            type: "warning"
          }
        );
      } catch (error) {
        return;
      }

      this.isLoading = true;
      await this.$store.dispatch("deleteProject_person", { id: external.id });
      // TODO: Add delete call
      this.load();
    },
    onEdit(external) {
      this.external = external;
      this.showForm = true;
    }
  },
  mounted() {
    this.load();
  }
};
</script>
