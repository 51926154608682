<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <!-- <h6 class="h2 d-inline-block mb-0">Projekte</h6> -->
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <!-- <base-button size="sm" type="primary" @click="$router.push({ path: '/admin/projects/add'})">
            <i class="ni ni-fat-add"></i>Neues Projekt
          </base-button>
           <base-button size="sm" type="primary" @click="$router.push({ path: '/admin/projects/add'})">
            <i class="ni ni-fat-add"></i>Neu aus Vorlage
          </base-button> -->
          <!-- <base-button size="sm" type="neutral">Filters</base-button> -->
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="border-0 card-header">
              <div class="row">
                <div class="col-6">
                  <h3 class="mb-0">{{ $t('message.user') }}</h3>
                </div>
                <div class="col-6 text-right">
                  <base-button type="primary" size="sm" @click="$router.push({ path: '/admin/users/add' })">
                    {{ $t('message.new') }}
                  </base-button>
                  <!-- <base-split-button type="primary" size="sm" text="Neues Projekt">
                            <a class="dropdown-item">Neu aus Vorlage</a>
                        </base-split-button> -->
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col m-3">
                <base-input v-model="searchText" :placeholder="$t('global.search')"></base-input>
              </div>
            </div>
            <el-table class="table-responsive table-flush" header-row-class-name="thead-light" :data="items">
              <el-table-column :label="$t('message.name')" prop="name" sortable>

              </el-table-column>
              <el-table-column :label="$t('message.email')" prop="email" sortable>

              </el-table-column>
              <el-table-column align="right" width="90">
                <template v-slot="{ row }">
                  <el-dropdown trigger="click" class="dropdown">
                    <span class="btn btn-sm btn-icon-only text-light">
                      <i class="fas fa-ellipsis-v mt-2"></i>
                    </span>
                    <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                      <a class="dropdown-item clickable" @click="$router.push({ path: `/admin/users/${row.id}` })">{{
                        $t('message.edit')
                      }}</a>
                      <a class="dropdown-item clickable" @click.prevent="onDelete(row)" href="#">{{
                        $t('message.delete')
                      }}</a>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>

            <div class="card-footer py-4 d-flex justify-content-end">
              <!-- <base-pagination v-model="currentPage" :total="50"></base-pagination> -->
            </div>
          </div>
        </div>
      </div>
      <!-- <inline-actions-table></inline-actions-table>
      <striped-table></striped-table>
      <checkbox-table></checkbox-table>
      <checkbox-colored-table></checkbox-colored-table>
      <translucent-table></translucent-table>
      <dark-table></dark-table> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { debounce } from "lodash";

export default {
  name: 'RolesView',
  components: {

  },
  props: ['data', 'employees'],
  computed: {
    ...mapGetters([
      'roles',
      'users'
    ]),
    searchText: {
      get() {
        return this.search
      },
      set: debounce(function (val) {
        this.search = val
      }, 500)
    },
    items() {
      let result = this.users;

      if (this.searchText) {
        const searchText = this.searchText.toLowerCase();
        result = result.filter((r) => r.name?.toLowerCase().includes(searchText) || r.email?.toLowerCase().includes(searchText)
        );
      }

      return result;
    }
  },
  data() {
    return {
      addUser: false,
      vehicle: null,
      employee: null,
      project: {
        date: null
      },
      modals: {
        addHotel: false,
        deleteHotel: false
      },
      dateTimePicker: {
        enableTime: true,
        dateFormat: 'd-m-Y H:i'
      },
      search: "",
    }
  },
  methods: {
    onDelete: async function (user) {
      try {
        await this.$confirm(
          `Sind Sie sicher?`,
          `Benutzer löschen`,
          {
            confirmButtonText: this.$t("message.yes"),
            cancelButtonText: this.$t("message.no"),
            type: "warning"
          }
        )
        await this.$store.dispatch('deleteUser', { id: user.id })
        await this.$store.dispatch('findUsers')
      } catch (error) {
        // this.$notify({
        //   verticalAlign: "top",
        //   horizontalAlign: "right",
        //   message: this.$t('message.delete_error'),
        //   type: "error"
        // });
      }
    }
  },
  mounted() {
    this.$store.dispatch('findUsers')
  }
}
</script>
<style>
.texto_grande {
  font-size: 1.0rem;

}

#icone_grande {
  font-size: 8rem;
}
</style>
