<template>
  <div class="card" v-loading="isLoading">
    <div class="card-header">
      <div class="d-flex">
        <h3 class="m-0 pr-4 flex-grow-1">{{$t('message.tools')}}</h3>
        <el-tooltip :content="$t('message.new')" placement="top">
          <base-button type="primary" icon round size="sm" @click="onCreate" :disabled="project.state === 'ARCHIVED'">
            <i class="fas fa-plus p-0"></i>
          </base-button>
        </el-tooltip>
      </div>
    </div>

    <el-table
      class="table-responsive table-compact"
      header-row-class-name="thead-light"
      row-key="id"
      :data="tools"
    >
      <el-table-column :label="$t('message.name')" prop="name" sortable />
      <el-table-column :label="$t('message.amount')" prop="count" sortable />
      <el-table-column :label="$t('message.location')" prop="address.name" sortable />
      <el-table-column label width="88">
        <template v-slot="{row}">
          <el-tooltip :content="$t('message.edit')" placement="top">
            <base-button type="link" size="sm" @click="onEdit(row)" :disabled="project.state === 'ARCHIVED'">
              <i class="fas fa-edit text-black"></i>
            </base-button>
          </el-tooltip>
          <el-tooltip :content="$t('message.delete')" placement="top">
            <base-button type="link" size="sm" @click="onDelete(row)" :disabled="project.state === 'ARCHIVED'">
              <i class="fas fa-trash text-black"></i>
            </base-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <tools-form v-if="tool" :show.sync="showForm" :tool="tool" @changed="onChanged" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import api from "@/util/api.js";
import ToolsForm from "./ToolsForm";

export default {
  name: "tools",
  components: { ToolsForm },
  props: {
    project: { type: Object }
  },
  data() {
    return {
      isLoading: true,
      tools: [],
      tool: undefined,
      showForm: false
    };
  },
  mounted() {
    this.load();
  },
  computed: {
    ...mapGetters([
      "positions",
      "projectAddresses"
    ])
  },
  methods: {
    load: async function() {
      const tools = await api("findProject_tools")
        .and("project_id", this.project.id)
        .run();

      this.tools = tools.map((tool) => {
        const addr = this.projectAddresses.find(addr => addr.address_id === tool.address_id);
        tool.address = addr.address;
        tool.positions = this.positions.filter((item) => item.project_tool_id === tool.id);
        return tool;
      });

      this.isLoading = false;
    },
    onChanged() {
      this.isLoading = true;
      this.load();
    },
    onCreate() {
      const { project, projectAddresses } = this;
      this.tool = {
        address_id: projectAddresses.length
          ? projectAddresses[0].address.id
          : null,
        project_id: project.id
      };

      this.showForm = true;
    },
    onDelete: async function(item) {
      try {
        await this.$confirm(
          this.$t('message.notificationToolDelete'),
          this.$t('message.notificationToolDeleted'),
          {
            confirmButtonText: this.$t("message.yes"),
            cancelButtonText: this.$t("message.no"),
            type: "warning"
          }
        );
      } catch (error) {
        return;
      }

      this.isLoading = true;
      await this.$store.dispatch("deleteProject_tool", { id: item.id });
      this.load();
    },
    onEdit(tool) {
      this.showForm = true;
      this.tool = { ...tool };
    }
  }
};
</script>
