<template>
  <div class="col-md-6">
    <label class="col-form-label form-control-label">{{ label }}</label>
    <flat-picker
      class="form-control"
      placeholder="Termin auswählen"
      :config="dateTimePicker"
      :value="value"
      @input="onChange"
    ></flat-picker>
  </div>
</template>

<script>
import "flatpickr/dist/flatpickr.css";

import FlatPicker from "vue-flatpickr-component";

export default {
  name: "date-picker",
  components: {
    FlatPicker
  },
  props: {
    label: {
      type: String
    },
    value: {
      type: String
    }
  },
  data() {
    return {
      dateTimePicker: {
        enableTime: true,
        time_24hr: true,
        dateFormat: "Y-m-d H:i:00",
        altInput: true,
        altFormat: "d.m.Y H:i"
      }
    };
  },
  methods: {
    onChange(value) {
      this.$emit("input", value);
    }
  }
};
</script>
