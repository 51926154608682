<template>
  <div>
    <div class="form-group row">
        <label class="col-md-6 col-form-label form-control-label">Laden</label>
        <div class="col-md-6">
          <flat-picker :config="configs.dateTimePicker"
                            id="datetime-input"
                            class="form-control"
                            v-model="data.laden"
                            placeholder="Termin auswählen"></flat-picker>
          </div>
      </div>
      <div class="form-group row">
        <label class="col-md-6 col-form-label form-control-label">Abfahrt</label>
        <div class="col-md-6">
          <flat-picker :config="configs.dateTimePicker"
                            id="datetime-input"
                            class="form-control"
                            v-model="data.abfahrt"
                            placeholder="Termin auswählen"></flat-picker>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-6 col-form-label form-control-label">Abladen vor Ort</label>
        <div class="col-md-6">
           <flat-picker :config="configs.dateTimePicker"
              id="datetime-input"
              class="form-control"
              v-model="data.abladenOrt"
              placeholder="Termin auswählen"></flat-picker>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-6 col-form-label form-control-label">Montagebegin</label>
        <div class="col-md-6">
           <flat-picker :config="configs.dateTimePicker"
              id="datetime-input"
              class="form-control"
              v-model="data.abladenOrt"
              placeholder="Termin auswählen"></flat-picker>
        </div>
      </div>
        <div class="form-group row">
        <label class="col-md-6 col-form-label form-control-label">Aufladen vor Ort</label>
        <div class="col-md-6">
          <flat-picker :config="configs.dateTimePicker"
              id="datetime-input"
              class="form-control"
              v-model="data.aufladenORt"
              placeholder="Termin auswählen"></flat-picker>
        </div>
      </div>
        <div class="form-group row">
        <label class="col-md-6 col-form-label form-control-label">Abladen bei APA</label>
        <div class="col-md-6">
          <flat-picker :config="configs.dateTimePicker"
              id="datetime-input"
              class="form-control"
              v-model="data.abladenAPA"
              placeholder="Termin auswählen"></flat-picker>
        </div>
      </div>
  </div>

</template>

<script>
  import FileInput from '@/components/Inputs/FileInput'
  import flatPicker from "vue-flatpickr-component"
  import "flatpickr/dist/flatpickr.css"

  export default {
    name: 'form-elements',
    components: {
      FileInput,
      flatPicker
    },
    data() {
      return {
        data: {
          laden: null,
          abfahrt: null
        },
        configs: {
          dateTimePicker: {
            enableTime: true,
            dateFormat: 'd.m.Y H:i',
            time_24hr: true
          }
        }
      }
    },
    methods: {
      filesChange(files) {
        this.inputs.file = files
      }
    }
  }
</script>
