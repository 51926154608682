<template>
  <modal @update:show="onShowChange" :show="show">
    <h6 slot="header" class="modal-title">{{ insurance.id ? $t('insurance.edit') : $t('insurance.edit') }}</h6>

    <div v-loading="isLoading">
      <base-input :label="$t('insurance.ride_label')" :validator="$v.insurance.ride_id">
        <el-select v-model="insurance.ride_id" filterable :placeholder="$t('insurance.input_select_ride_placeholder')">
          <el-option
            v-for="ride in rides"
            :key="ride.id"
            :label="`${ride.load}, ${ride.vehicle.license}`"
            :value="ride.id"
          ></el-option>
        </el-select>
      </base-input>

      <div class="row" v-for="item in items" v-bind:key="item.name">
        <div class="col-md-4">
          <base-checkbox v-model="item.checked">{{item.name}}</base-checkbox>
        </div>
        <div class="col-md-4">
          <base-checkbox v-model="item.equipment" v-if="'equipment' in item">{{$t('insurance.equipment_included')}}</base-checkbox>
        </div>
        <div class="col-md-1">
          <div  v-if="'count' in item">
            <base-input type="number" v-model="item.count"></base-input>
          </div>
        </div>
        <div class="col-md-3">
          <div  v-if="'count' in item">
            {{$t('insurance.piece_module')}}
          </div>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-12">
          <base-input required :label="$t('insurance.reason')" :placeholder="$t('insurance.license_plate')"
            v-model="insurance.reason" :validator="$v.insurance.reason"></base-input>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-12">
          <h4>{{$t('insurance.insurance_topic_label')}}</h4>
        </div>
        <div class="col-md-6">
          <base-input :label="$t('insurance.by_apa')">
            <base-checkbox v-model="duty.apa.transport">{{$t('insurance.transport')}}</base-checkbox>
            <base-checkbox v-model="duty.apa.onsite">{{$t('insurance.stay_on_site')}}</base-checkbox>
          </base-input>
        </div>
        <div class="col-md-6">
          <base-input :label="$t('insurance.by_customer')">
            <base-checkbox v-model="duty.customer.transport">{{$t('insurance.transport')}}</base-checkbox>
            <base-checkbox v-model="duty.customer.onsite">{{$t('insurance.stay_on_site')}}</base-checkbox>
          </base-input>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-6">
          <base-input type="number" required :label="$t('insurance.value_of_goods')" :placeholder="$t('insurance.value_currency')"
            v-model="insurance.amount" :validator="$v.insurance.amount"></base-input>
        </div>
        <div class="col-md-6">
          <base-input type="number" required :label="$t('insurance.transport_weight')" placeholder="kg"
            v-model="insurance.weight" :validator="$v.insurance.weight"></base-input>
        </div>
      </div>
    </div>

    <template slot="footer">
      <base-button type="link" class="ml-auto" @click="onClose">{{$t('global.cancel')}}</base-button>
      <base-button type="primary" @click="onSave" :disabled="$v.$invalid">{{$t('global.save')}}</base-button>
    </template>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Insuranceform",
  components: {
  },
  props: {
    insurance: {
      type: Object
    },
    rides: {
      type: Array
    },
    show: {
      type: Boolean
    }
  },
  async mounted() {
    this.isLoading = false;
  },
  data() {
    return {
      isLoading: true,
      duty: {
        apa: {transport: false, onsite: false},
        customer: {transport: false, onsite: false},
      },
      itemsTemplate: [
        {name: "Sportveranstaltung", checked: false},
        {name: "Handelsware", checked: false},
        {name: "Werbemittel", checked: false},
        {name: "Messe", checked: false},
        {name: "Sonstiges", checked: false},
        {name: "Equipment DFB LSP", checked: false},
        {name: "Equipment DFB-Pokal", checked: false},
        {name: "Vermietung Roto", checked: false, equipment: false, count: null},
        {name: "Vermietung Leuchtbande", checked: false, equipment: false, count: null},
        {name: "Verkauf Roto", checked: false, equipment: false, count: null},
        {name: "Verkauf Leuchtbande", checked: false, equipment: false, count: null},
        {name: "Verkauf sonstiges", checked: false, count: null},
        {name: "Wartung von Roto", checked: false},
      ],
      items: [],
    };
  },
  watch: {
    insurance() {
      const items = JSON.parse(this.insurance.items) || []
      this.items = this.itemsTemplate.map(item => {
        const found = items.find(i => i.name == item.name)
        return found || Object.assign({}, item)
      })
      const apa = this.insurance.apa ? this.insurance.apa.split(',') : []
      const customer = this.insurance.customer ? this.insurance.customer.split(',') : []
      this.duty.apa.transport = apa.indexOf('TRANSPORT')!=-1
      this.duty.apa.onsite = apa.indexOf('ONSITE')!=-1
      this.duty.customer.transport = customer.indexOf('TRANSPORT')!=-1
      this.duty.customer.onsite = customer.indexOf('ONSITE')!=-1
    }
  },
  validations: {
    insurance: {
      ride_id: {required},
      amount: {required},
      weight: {required},
    },
  },
  methods: {
    onClose() {
      this.onShowChange(false);
    },
    fromDuty(d) {
      const result = []
      if (d.transport) result.push('TRANSPORT')
      if (d.onsite) result.push('ONSITE')
      return result.join(',')
    },
    onSave: async function() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return
      }

      const { $store, insurance } = this;
      this.isLoading = true;

      this.insurance.items = JSON.stringify(this.items)
      this.insurance.apa = this.fromDuty(this.duty.apa)
      this.insurance.customer = this.fromDuty(this.duty.customer)

      if (insurance.id) {
        await $store.dispatch("updateTransport_insurance", {
          id: insurance.id,
          data: insurance
        });
      } else {
        await $store.dispatch("createTransport_insurance", { data: insurance });
      }

      this.isLoading = false;
      this.onClose();
      this.$emit("changed");
    },
    onShowChange(state) {
      this.$emit("update:show", state);
    }
  }
};
</script>
