<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="border-0 card-header">
                <div class="row">
                    <div class="col-6">
                        <h3 class="mb-0">Aufgabenteams</h3>
                    </div>
                    <div class="col-6 text-right">
                      <base-button type="primary" size="sm" @click="$router.push({ path: '/admin/teams/form/new'})">
                        Neues Team
                      </base-button >
                    </div>
                </div>
            </div>

            <el-table class="table-responsive table-flush"
                      header-row-class-name="thead-light"
                      :data="items">
                <el-table-column label="Name"
                                sortable>
                      <template v-slot="{row}">
                        {{ row.name }}
                      </template>
                </el-table-column>
                <el-table-column label="Mitglieder">
                    <template v-slot="{row}">
                        {{teamMember(row.user_ids)}}
                    </template>
                </el-table-column>
                <el-table-column label="Teamchef">
                    <template v-slot="{row}">
                        {{teamLeader(row.team_leader_id)}}
                    </template>
                </el-table-column>
                <el-table-column align="right" width="90">
                    <template v-slot="{row}">
                        <el-dropdown trigger="click" class="dropdown">
                        <span class="btn btn-sm btn-icon-only text-light">
                          <i class="fas fa-ellipsis-v mt-2"></i>
                        </span>
                            <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                <a class="dropdown-item" href="#" @click.prevent="$router.push({ path: '/admin/teams/form/'+row.id})">Bearbeiten</a>
                                <a class="dropdown-item" href="#" @click.prevent="remove(row.id)">Löschen</a>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>

        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import api from '@/util/api.js'

  export default {
    name: 'TeamsView',
    computed: {
      ...mapGetters([
        'users'
      ])
    },
    data() {
      return {
        item: {},
        items: [],
      }
    },
    methods: {
      reset() {
        api('findTask_teams')
          .run()
          .then(items => this.items = items)
      },
      remove(id) {
        this.$store.dispatch('deleteTask_teams', {id: id})
          .then(res => this.reset())
          .catch(error => alert('Konnte nicht löschen'))
      },
      teamLeader(leader_id){
        let leader = this.users.filter(u => u.id == leader_id);
        return leader[0].name;
      },
      teamMember(ids) {
        let members = ids.split(',')
        return members.length
      }
    },
    mounted() {
      this.reset();
    }
  }
</script>
