<template>
  <modal @update:show="onShowChange" :show="show">
    <h6
      slot="header"
      class="modal-title"
    >{{ tool.id ? this.$t('message.EditTool') : this.$t('message.NewTool') }}</h6>

    <div v-loading="isLoading">
      <base-input :label="$t('message.einsatzort')" :validator="$v.tool.address_id">
        <el-select v-model="tool.address_id" filterable :placeholder="$t('message.chooseEinsatzort')">
          <el-option
            v-for="address in projectAddresses"
            :key="address.address.id"
            :label="address.address.name + ', ' + address.address.zip + ' ' + address.address.city"
            :value="address.address.id"
          ></el-option>
        </el-select>
      </base-input>

      <div class="row">
        <div class="col-md-6">
          <base-input :label="$t('message.amount')" :placeholder="$t('message.amount')" v-model="tool.count" :validator="$v.tool.count"></base-input>
        </div>
        <div class="col-md-6">
          <base-input :label="$t('message.tools')" :placeholder="$t('message.tools')" v-model="tool.name" :validator="$v.tool.name"></base-input>
        </div>
      </div>
      <div class="row">
        <date-picker :label="$t('message.firstUsageTool')" v-model="tool.date1_from" />
        <date-picker :label="$t('message.firstUsageUntil')" v-model="tool.date1_to" />
      </div>
      <div class="row">
        <date-picker :label="$t('message.delivery')" v-model="tool.delivery_date1" />
        <date-picker :label="$t('message.pickup')" v-model="tool.pick_up_date1" />
      </div>
      <div class="row">
        <div class="col-md-6">
          <base-checkbox v-model="tool.stand_by_date1">Standby</base-checkbox>
        </div>
      </div>
      <div class="row">
        <date-picker :label="$t('message.secondUsageTool')" v-model="tool.date2_from" />
        <date-picker :label="$t('message.secondUsageUntil')" v-model="tool.date2_to" />
      </div>
      <div class="row">
        <date-picker :label="$t('message.delivery')" v-model="tool.delivery_date2" />
        <date-picker :label="$t('message.pickup')" v-model="tool.pick_up_date2" />
      </div>
      <div class="row">
        <div class="col-md-6">
          <base-checkbox v-model="tool.stand_by_date2">Standby</base-checkbox>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12">
          <base-input :label="$t('message.notice')" :validator="$v.tool.description">
            <el-input type="textarea" autosize v-model="tool.description" :validator="$v.tool.description"></el-input>
          </base-input>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12">
          <base-input :label="$t('message.position')">
            <ul>
              <li v-for="position in tool.positions">
                <router-link :to="{ path: `/project/${$route.params.id}/data/${position.id}` }">
                  {{ position.key }}
                </router-link>
              </li>
            </ul>
          </base-input>
        </div>
      </div>
    </div>

    <template slot="footer">
      <base-button type="link" class="ml-auto" @click="onClose">{{$t('message.cancel')}}</base-button>
      <base-button type="primary" @click="onSave" :disabled="$v.$invalid">{{$t('message.save')}}</base-button>
    </template>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import DatePicker from "./DatePicker";

export default {
  name: "tools-forms",
  components: { DatePicker },
  props: {
    show: { type: Boolean },
    tool: { type: Object }
  },
  data() {
    return {
      isLoading: false
    };
  },
  validations: {
    tool: {
      address_id: {required},
      count: {required},
      date1_from: {required},
      date1_to: {required},
      description: {required},
    },
  },
  watch: {
    'tool.date1_from' (val) {
      if (val) {
        if (!this.tool.date1_to) {
          this.tool.date1_to = val
        }
      }
    },
    'tool.date2_from' (val) {
      if (val) {
        if (!this.tool.date2_to) {
          this.tool.date2_to = val
        }
      }
    }
  },
  computed: {
    ...mapGetters(["projectAddresses"])
  },
  methods: {
    onClose() {
      this.$v.$reset()

      this.onShowChange(false);
    },
    onSave: async function() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return
      }

      const { $store, tool } = this;
      this.isLoading = true;

      if (tool.id) {
        await $store.dispatch("updateProject_tool", {
          id: tool.id,
          data: tool
        });
      } else {
        await $store.dispatch("createProject_tool", { data: tool });
      }
      this.$v.$reset()
      this.isLoading = false;
      this.onClose();
      this.$emit("changed");
    },
    onShowChange(state) {
      this.$emit("update:show", state);
    }
  },
};
</script>
