<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <!-- <h6 class="h2 d-inline-block mb-0">Projekte</h6> -->
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <!-- <base-button size="sm" type="primary" @click="$router.push({ path: '/admin/projects/add'})">
            <i class="ni ni-fat-add"></i>Neues Projekt
          </base-button>
           <base-button size="sm" type="primary" @click="$router.push({ path: '/admin/projects/add'})">
            <i class="ni ni-fat-add"></i>Neu aus Vorlage
          </base-button> -->
          <!-- <base-button size="sm" type="neutral">Filters</base-button> -->
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <project-table :projects="allProjects"/>
        </div>
      </div>
      <!-- <inline-actions-table></inline-actions-table>
      <striped-table></striped-table>
      <checkbox-table></checkbox-table>
      <checkbox-colored-table></checkbox-colored-table>
      <translucent-table></translucent-table>
      <dark-table></dark-table> -->
    </div>
  </div>
</template>
<script>
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
  import  ProjectTable from '@/components/ProjectTable'
  import { mapGetters } from 'vuex'

  export default {
    components: {
      ProjectTable,
      RouteBreadCrumb
    },
    computed: {
      ...mapGetters([
        'allProjects'
      ])
    },
    data() {
      return {
        currentPage: 1
      }
    },
    mounted () {
      // this.$store.dispatch('findProjects')
    }
  }
</script>
