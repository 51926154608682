<template>
  <div v-loading="isLoading">
    <h4 v-if="label">{{label}}</h4>

    <comment-form class="mb-4" :at-values="atUsers" :createComment="onCreate" :triggerReload="onReload" :attachmentUploaded="onUpload"/>
    <comment
      :at-values="atUsers"
      :comment="comment"
      :createComment="onCreate"
      :triggerReload="onReload"
      :key="comment.id"
      v-for="comment in comments"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from "moment";

import Comment from "@/components/Feed/Comment";
import CommentForm from "@/components/Feed/CommentForm";
import createComment from '@/store/modules/comments/createComment';
import toHierarchy from '@/store/modules/comments/toHierarchy';

export default {
  name: "debriefing-comments",
  components: {
    Comment,
    CommentForm
  },
  props: {
    fixtures: {
      type: Object,
    },
    hideComments: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    autoRefresh: {
      boolean: false
    }
  },
  computed: {
    ...mapGetters([
      'atUsers',
    ])
  },
  data() {
    return {
      comments: [],
      isLoading: false,
    };
  },
  watch: {
    fixtures() {
      this.comments = [];
      this.loadComments();
    }
  },
  methods: {
    onCreate: async function(comment) {
      const created = moment().format("YYYY-MM-DD HH:mm:ss");
      const { $store, fixtures } = this;
      const { id: user_id } = $store.getters.currentUser;
      //fixtures.intern = this.currentUser.role_id === 14 || this.currentUser.role_id === 13 ? 1 : 0;
      const result = await $store.dispatch('createComment', {
        data: createComment({
          ...comment,
          ...fixtures,
          user_id,
          created
        })
      });

      this.$emit('created');
      return result
    },
    onUpload: async function(){
      await this.loadComments()
      await this.onReload();
    },
    onReload: async function () {
      await this.loadComments()
    },
    loadComments: async function() {
      if (this.hideComments) {
        this.isLoading = false;
        return;
      }

      this.isLoading = true;
      const comments = await this.$store.dispatch('loadComments', {
        filter: this.fixtures,
      });

      this.isLoading = false;
      this.comments = toHierarchy(comments);
    }
  },
  mounted() {
    this.loadComments();

    if (this.autoRefresh) {
      this.timer = setInterval(() => this.onReload(), 5000)
    }
  },
  destroyed () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
};
</script>

