<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <!-- <h6 class="h2 d-inline-block mb-0">Projekte</h6> -->
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <!-- <base-button size="sm" type="primary" @click="$router.push({ path: '/admin/projects/add'})">
            <i class="ni ni-fat-add"></i>Neues Projekt
          </base-button>
           <base-button size="sm" type="primary" @click="$router.push({ path: '/admin/projects/add'})">
            <i class="ni ni-fat-add"></i>Neu aus Vorlage
          </base-button> -->
          <!-- <base-button size="sm" type="neutral">Filters</base-button> -->
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="border-0 card-header">
                <div class="row">
                    <div class="col-6">
                        <h3 class="mb-0">Alle Rollen</h3>
                    </div>
                    <div class="col-6 text-right">
                      <base-button type="primary" size="sm" @primary-click="$router.push({ path: '/admin/projects/add'})">
                        Neue Rolle
                      </base-button >
                        <!-- <base-split-button type="primary" size="sm" text="Neues Projekt">
                            <a class="dropdown-item">Neu aus Vorlage</a>
                        </base-split-button> -->
                    </div>
                </div>
            </div>

            <el-table class="table-responsive table-flush"
                      header-row-class-name="thead-light"
                      :data="roles">
                <el-table-column label="Rolle"
                                prop="name"
                                sortable>
                
                </el-table-column>
                <el-table-column label="Beschreibung"
                                prop="description"
                                sortable>
                
                </el-table-column>
                <el-table-column align="right" width="90">
                    <template v-slot="{row}">
                        <el-dropdown trigger="click" class="dropdown">
                        <span class="btn btn-sm btn-icon-only text-light">
                          <i class="fas fa-ellipsis-v mt-2"></i>
                        </span>
                            <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                <a class="dropdown-item" href="#">Bearbeiten</a>
                                <a class="dropdown-item" href="#">Kopieren</a>
                                <a class="dropdown-item" href="#">Löschen</a>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>

            <div class="card-footer py-4 d-flex justify-content-end">
                <!-- <base-pagination v-model="currentPage" :total="50"></base-pagination> -->
            </div>
        </div>
        </div>
      </div>
      <!-- <inline-actions-table></inline-actions-table>
      <striped-table></striped-table>
      <checkbox-table></checkbox-table>
      <checkbox-colored-table></checkbox-colored-table>
      <translucent-table></translucent-table>
      <dark-table></dark-table> -->
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'RolesView',
    components: {
   
    },
    props: ['data', 'employees'],
    computed: {
      ...mapGetters([
        'roles'
      ])
    },
    data() {
      return {
        addUser: false,
        vehicle: null,
        employee: null,
        project: {
          date: null
        },
        modals: {
          addHotel: false,
          deleteHotel: false
        },
        dateTimePicker: {
          enableTime: true,
          dateFormat: 'd-m-Y H:i'
        }
      }
    },
    methods: {
      filesChange(files) {
        this.inputs.file = files
      }
    }
  }
</script>
<style>
.texto_grande {
    font-size: 1.0rem;

} 
#icone_grande {
    font-size: 8rem;
} 
</style>
