<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="primary" @click="save">Speichern</base-button>
          <base-button size="sm" type="neutral" @click="$router.push({ path: '/admin/teams'})">Abbrechen</base-button>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">Team</h3>
        </div>
        <!-- Card body -->
        <div class="card-body">

          <div class="row">
            <div class="col-md-6">
              <base-input required label="Name" placeholder="Name" v-model="item.name"></base-input>
            </div>
            <div class="col-md-6">
              <base-input label="Teamchef">
                <el-select v-model="item.team_leader_id" filterable>
                  <el-option v-for="option in allowedUsers"
                            :key="option.id"
                            :label="option.name"
                            :value="option.id">
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <base-input label="Teammitglieder">
                <div class="mb-2" v-for="(current, counter) in currentMembers(item)" :key="counter">
                  <el-select
                        class="select-primary col-6"
                        v-model="current.user_id"
                        filterable
                        placeholder="Mitglied"
                        @change="setNewUserIds(current, counter)">
                    <el-option v-for="member in allowedUsers"
                              class="select-primary"
                              :value="member.id"
                              :label="member.name"
                              :key="member.id">
                    </el-option>
                  </el-select>
                  <span class="col-4 text-right" v-if="counter === 0">
                    <el-tooltip :content="$t('message.add')" placement="top">
                        <i class="fas fa-plus-circle text-primary"
                          @click="addMember"></i>
                    </el-tooltip>
                  </span>
                  <span class="col-4 text-right" v-if="counter > 0">
                    <el-tooltip :content="$t('message.delete')" placement="top">
                          <i class="fas fa-minus-circle"
                          @click="removeMember(counter, 'current')"></i>
                      </el-tooltip>
                  </span>
                </div>
              </base-input>
              <div v-if="(additionalMembers.length)">
                <div v-for="(newMember, counter) in additionalMembers" :key="counter">
                  <base-input >
                    <el-select
                          class="select-primary col-6"
                          v-model="newMember.user_id"
                          filterable
                          placeholder="Mitglied">
                      <el-option v-for="member in allowedUsers"
                                class="select-primary"
                                :value="member.id"
                                :label="member.name"
                                :key="member.id">
                      </el-option>
                    </el-select>
                    <span class="col-4 text-right">
                      <el-tooltip :content="$t('message.delete')" placement="top">
                          <i class="fas fa-minus-circle"
                          @click="removeMember(counter, 'additional')"></i>
                      </el-tooltip>
                    </span>
                  </base-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { TASK_EXTERN_ROLES } from "@/store/modules/tasks/constants";

  export default {
    name: 'TeamsFormView',
    computed: {
      ...mapGetters([
        'users',
        'roles'
      ]),
      allowedUsers() {
        const internalRoles = this.roles.filter(r => !TASK_EXTERN_ROLES.includes(r.name));
        let internalRoleIds = [];
        internalRoles.forEach( ir => internalRoleIds.push(ir.id));
        const internalUsers = this.users.filter(u => internalRoleIds.includes(u.role_id));
        return internalUsers;
      }
    },
    data() {
      return {
        item: {},
        additionalMembers: [],
        currentUsers: []
      }
    },
    methods: {
      getId() {
        return this.$route.params.id=='new' ? null : this.$route.params.id;
      },
      save() {
        const newUserIds = this.currentUsers
                            .concat(this.additionalMembers.filter(i => i.user_id != ''))
                            .map(item => item.user_id)
                            .join(",");
        this.item.user_ids = newUserIds;
        
        const done = this.getId()
          ? this.$store.dispatch('updateTask_teams', {id: this.getId(), data: this.item})
          : this.$store.dispatch('createTask_teams', {data: this.item});
        done.then( this.$router.push({ path: '/admin/teams'}) )
      },
      currentMembers(item) {
        if(item.user_ids) {
          item.user_ids.split(',').forEach((value, index) => 
            this.currentUsers[index] = {user_id: parseInt(value)}
          );
        } else {
          this.currentUsers[0] = {user_id: null}
        }
        return this.currentUsers
      },
      addMember() {
        this.additionalMembers.push({
          user_id: ''
        })
      },
      removeMember(counter, type) {
        if(type === 'additional') {
          this.additionalMembers.splice(counter,1);
        }
        if(type === 'current') {
          this.currentUsers.splice(counter,1);
          this.item.user_ids = this.currentUsers.map(item => item.user_id).join(",");
        }
      },
      setNewUserIds(val, counter) {
        let newIds = [];
        if(this.item.user_ids) {
          this.item.user_ids.split(',').forEach((value, index) => {
              if(index == counter) {
                value = val.user_id
              }
              newIds.push(value)
            });
          this.item.user_ids = newIds.join(',');
        } else {
          this.item.user_ids = String(val.user_id);
          this.currentUsers[counter] = {user_id: parseInt(val.user_id)}
        }
      }
    },
    mounted () {
      if (!this.getId()) {
        this.item = {}
      } else {
        this.$store.dispatch('getTask_teams', {id: this.getId()}).then(item => {
          this.item = Object.assign({}, item)
        })
      }
    }
  }
</script>