<template>
  <div class="card" v-loading="isLoading">
    <div class="card-header">
      <div class="d-flex">
        <h3 class="m-0 pr-4 flex-grow-1">{{$t('message.accommodations')}}</h3>
<!--        <el-tooltip content="Neu" placement="top">
          <base-button type="primary" icon round size="sm" @click="onCreate">
            <i class="fas fa-plus p-0"></i>
          </base-button>
        </el-tooltip> -->
      </div>
    </div>

    <el-table
      class="table-responsive table-compact"
      header-row-class-name="thead-light"
      row-key="id"
      :data="person_addresses"
    >
      <el-table-column :label="$t('message.einsatzorte')" prop="address.name" sortable />
      <el-table-column :label="$t('message.employee')" prop="user.name" sortable />
      <el-table-column :label="$t('message.present')" prop="onsite_from" sortable>
        <template v-slot="{row}">{{row.onsite_from | date}} - {{row.onsite_to|date}}</template>
      </el-table-column>
      <el-table-column :label="$t('message.hotel')" prop="hotel" sortable>
        <template v-slot="{row}">{{row.hotel ? `${row.hotel.name}, ${row.hotel.city}` : ''}}</template>
      </el-table-column>
      <el-table-column label width="88">
        <template v-slot="{row}">
          <el-tooltip :content="$t('message.edit')" placement="top">
            <base-button type="link" size="sm" @click="onEdit(row)" :disabled="project.state === 'ARCHIVED'">
              <i class="fas fa-edit text-black"></i>
            </base-button>
          </el-tooltip>
          <el-tooltip :content="$t('message.delete')" placement="top">
            <base-button type="link" size="sm" @click="onDelete(row)" :disabled="project.state === 'ARCHIVED'">
              <i class="fas fa-trash text-black"></i>
            </base-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <hotels-form :show.sync="showForm" :item="item" v-if="item" @changed="onChanged" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import api from "@/util/api.js"
import HotelsForm from "./HotelsForm"

export default {
  name: "hotels",
  components: {
    HotelsForm
  },
  props: ["project"],
  data() {
    return {
      isLoading: false,
//      items: undefined,
      item: undefined,
      showForm: false
    };
  },
  computed: {
    ...mapGetters(['person_addresses']),
    // items() {
    //   return this.person_addresses
    // }
  },
  // mounted() {
  //   this.loadItems();
  // },
  methods: {
    ...mapActions([
      'loadPersonAddresses',
    ]),
    onChanged() {
      this.loadPersonAddresses(this.project.id);
    },
    onCreate() {
      this.item = { project_id: this.project.id, address: {} };
      this.showForm = true;
    },
    onDelete: async function({ id }) {
      try {
        await this.$confirm(
          "Wollen Sie diese Unterbringung wirklich löschen?",
          "Unterbringung löschen",
          {
            confirmButtonText: this.$t("message.yes"),
            cancelButtonText: this.$t("message.no"),
            type: "warning"
          }
        );
      } catch (error) {
        return;
      }

      await this.$store.dispatch("deletePerson_address", { id });
      this.loadPersonAddresses(this.project.id);
    },
    onEdit(item) {
      this.item = { ...item };
      this.showForm = true;
    },
    // loadItems: async function() {
    //   this.isLoading = true;
    //   await api("findPerson_addresses")
    //     .and("project_id", this.project.id)
    //     .with("address")
    //     .with("user", { one: "users", this: "user_id" })
    //     .with("hotel", { one: "address", this: "hotel_id" })
    //     .run();
    //
    //   this.isLoading = false;
    // }
  }
};
</script>
