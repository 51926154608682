<template>
  <modal @update:show="onShowChange" :show="show">
    <h6
      slot="header"
      class="modal-title"
    >{{ item.id ? this.$t('message.accoEdit') : 'Neue Unterbringung' }}</h6>

    <div v-loading="isLoading">
      <base-input :label="$t('message.einsatzort')" :validator="$v.item.address_id">
        <el-select v-model="item.address_id" filterable :placeholder="$t('message.chooseEinsatzort')">
          <el-option
            v-for="location in locations"
            :key="location.address.id"
            :label="`${location.address.name}, ${location.address.zip} ${location.address.city}`"
            :value="location.address.id"
          ></el-option>
        </el-select>
      </base-input>

      <base-input :label="$t('message.employee')" :placeholder="$t('message.teamMemberSelect')" :validator="$v.item.user_id">
        <el-select
          v-model="item.user_id"
          filterable
          class="select-primary"
          :placeholder="$t('message.teamMemberSelect')">
          <el-option
            v-for="user in users"
            class="select-primary"
            :value="user.id"
            :label="user.name"
            :key="user.id"
          ></el-option>
        </el-select>
      </base-input>

      <div class="row form-group">
        <div class="col-md-6">
          <label class="form-control-label">{{$t('message.thereFrom')}}</label>
          <flat-picker
            :config="dateTimePicker"
            id="date-input"
            class="form-control"
            v-model="item.onsite_from"
            :placeholder="$t('message.ChooseDatePicker')"
          ></flat-picker>
        </div>
        <div class="col-md-6">
          <label class="form-control-label">{{$t('message.thereUntil')}}</label>
          <flat-picker
            :config="dateTimePicker"
            id="date-input"
            class="form-control"
            v-model="item.onsite_to"
            :placeholder="$t('message.ChooseDatePicker')"
          ></flat-picker>
        </div>
      </div>

      <base-input :label="$t('message.accommodations')" :validator="$v.item.hotel_id">
        <el-select v-model="item.hotel_id" filterable :placeholder="$t('message.accoSelect')">
          <el-option key="NEW" :label="$t('message.accoNew')" value="NEW"></el-option>
          <el-option
            v-for="hotel in hotels"
            :key="hotel.id"
            :label="`${hotel.name}, ${hotel.zip} ${hotel.city}`+rating(hotel)"
            :value="hotel.id"
          ></el-option>
        </el-select>
      </base-input>

      <div v-if="item.hotel_id == 'NEW'" class="border-left border-primary pt-1 pl-4">
        <base-input required label="Name" placeholder="Name" v-model="hotel.name" :validator="$v.hotel.name"></base-input>
        <div class="row">
          <div class="col-md-6">
            <base-input required label="Straße" placeholder="Straße" v-model="hotel.street" :validator="$v.hotel.street"></base-input>
          </div>
          <div class="col-md-6">
            <base-input required label="Hausnummer" placeholder="Hausnummer" v-model="hotel.no" :validator="$v.hotel.no"></base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <base-input required label="Plz" placeholder="Plz" v-model="hotel.zip" :validator="$v.hotel.zip"></base-input>
          </div>
          <div class="col-md-4">
            <base-input required label="Ort" placeholder="Ort" v-model="hotel.city" :validator="$v.hotel.city"></base-input>
          </div>
          <div class="col-md-4">
            <base-input required label="Land " placeholder="Land" v-model="hotel.country" :validator="$v.hotel.country"></base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <base-input
              required
              type="tel"
              label="Telefon"
              placeholder="Telefon"
              v-model="hotel.phone"
              :validator="$v.hotel.phone"
            ></base-input>
          </div>
          <div class="col-md-4">
            <base-input
              required
              type="tel"
              label="Mobil"
              placeholder="Mobil"
              v-model="hotel.mobile"
              :validator="$v.hotel.mobile"
            ></base-input>
          </div>
          <div class="col-md-4">
            <base-input
              required
              type="email"
              label="E-Mail"
              placeholder="E-Mail"
              v-model="hotel.email"
              :validator="$v.hotel.email"
            ></base-input>
          </div>
        </div>
      </div>
    </div>

    <template slot="footer">
      <base-button type="link" class="ml-auto" @click="onClose">{{$t('message.cancel')}}</base-button>
      <base-button type="primary" @click="onSave" :disabled="$v.$invalid">{{$t('message.save')}}</base-button>
    </template>
  </modal>
</template>

<script>
import "flatpickr/dist/flatpickr.css";
import flatPicker from "vue-flatpickr-component";
import { mapGetters } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";

import FileInput from "@/components/Inputs/FileInput";
import api from "@/util/api.js";

import Termine from "../Termine";

export default {
  name: "hotels-form",
  components: {
    FileInput,
    flatPicker,
    Termine
  },
  props: {
    item: {
      type: Object
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dateTimePicker: {
        enableTime: false,
        dateFormat: "Y-m-d 00:00:00",
        altInput: true,
        altFormat: "d.m.Y"
      },
      hotel: {},
      hotels: [],
      isLoading: false,
      users: []
    };
  },
  validations: {
    item: {
      address_id: {required},
      user_id: {required},
      onsite_from: {required},
      onsite_to: {required},
      hotel_id: {required},
    },
    hotel: {
      name: {required: requiredIf(function (form) {return this.item.hotel_id == 'NEW'})},
      street: {required: requiredIf(function (form) {return this.item.hotel_id == 'NEW'})},
      no: {required: requiredIf(function (form) {return this.item.hotel_id == 'NEW'})},
      zip: {required: requiredIf(function (form) {return this.item.hotel_id == 'NEW'})},
      city: {required: requiredIf(function (form) {return this.item.hotel_id == 'NEW'})},
      country: {required: requiredIf(function (form) {return this.item.hotel_id == 'NEW'})},
    },
  },

  computed: {
    ...mapGetters(["locations"])
  },
  mounted() {
    this.load();
  },
  watch: {
    item(newItem, oldItem) {
      if (oldItem.id !== newItem.id) {
        this.load();
      }
    },
    'item.onsite_from' (val) {
      if (val) {
        if (!this.item.onsite_to) {
          this.item.onsite_to = val
        }
      }
    }
  },
  methods: {
    load() {
      this.isLoading = true;
      Promise.all([this.loadUsers(), this.loadHotels()]).then(() => {
        this.isLoading = false;
      });
    },
    loadHotels: async function() {
      const hotels = await api("findAddresses")
        .and("type", "HOTEL")
        .run();

      this.hotels = [...hotels].sort((left, right) =>
        left.name.localeCompare(right.name)
      );
    },
    loadUsers: async function() {
      const persons = await api("findProject_people")
        .and("project_id", this.item.project_id)
        .with("user", { one: "users", this: "user_id" })
        .run();
      this.users = persons.filter((u) => u.role_id !== 18)
        .reduce(
          (existingUsers, { user }) =>
            existingUsers.some(existingUser => existingUser.id === user.id)
              ? existingUsers
              : [...existingUsers, user],
          []
        )
        .sort((left, right) => left.name.localeCompare(right.name));
    },
    onClose() {
      this.onShowChange(false);
    },
    onSave: async function() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return
      }

      const { $store, hotel, item } = this;
      this.isLoading = true;
      if (item.hotel_id == "NEW") {
        const hotelRecord = await $store.dispatch("createAddress", {
          data: {
            ...hotel,
            type: "HOTEL"
          }
        });

        this.hotels = [...this.hotels, hotelRecord];
        item.hotel_id = hotelRecord.id;
      }

      if (item.id) {
        const {user, hotel, ...data} = item;
        await $store.dispatch("updatePerson_address", {
          id: item.id,
          data: data
        });
      } else {
        await $store.dispatch("createPerson_address", { data: data });
      }

      this.isLoading = false;
      this.$emit("changed");
      this.onClose();
    },
    onShowChange(value) {
      this.$emit("update:show", value);
    },
    rating(hotel) {
      if (!hotel.rating) return "";
      let rating = Math.min(5, hotel.rating);
      rating = Math.max(1, rating);
      return ' ' + ''.padEnd(rating, '*')
    }
  }
};
</script>
