<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <!-- <h6 class="h2 d-inline-block mb-0">{{$route.name}}</h6> -->
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="primary" @click="save">{{$t('message.save')}}</base-button>
          <base-button size="sm" type="neutral" @click="$router.push({ path: '/admin/users'})">{{$t('message.cancel')}}</base-button>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">{{$t('message.user_information')}}</h3>
        </div>
        <!-- Card body -->
        <div class="card-body d-flex ">
          <!-- Form groups used in grid -->

          <div class="mr-4">

            <div class="apa-avatar">
              <div class="avatar-upload"  v-show="!avatarEdit">
                <div class="text-center p-2">
                  <label class="avatar-preview" for="avatar">
                    <img :src="avatarFiles.length ? avatarFiles[0].url : avatar ? avatar.url : ''"  class="avatar-img" />
                  </label>
                </div>
                <div class="text-center p-2 file-upload-container">
                  <file-upload
                    extensions="gif,jpg,jpeg,png,webp"
                    accept="image/png,image/gif,image/jpeg,image/webp"
                    name="avatar"
                    class="btn btn-default btn-sm mb-1"
                    :drop="!avatarEdit"
                    v-model="avatarFiles"
                    @input-filter="inputFilter"
                    @input-file="inputFile"
                    ref="upload">
                    {{$t('message.choose_image')}}
                  </file-upload>
                    <base-button size="sm" type="primary" @click="deleteAvatar" v-if="avatar">{{$t('message.delete_avatar')}}</base-button>
                </div>
              </div>

              <div class="avatar-edit" v-show="avatarFiles.length && avatarEdit">
                <div class="avatar-edit-image" v-if="avatarFiles.length">
                  <img ref="editImage" :src="avatarFiles[0].url" />
                </div>
                <div class="text-center p-4">
                  <button type="button" class="btn btn-secondary btn-sm" @click.prevent="clearAvatar">{{$t('message.cancel')}}</button>
                  <button type="submit" class="btn btn-primary btn-sm" @click.prevent="avatarEditSave">{{$t('message.apply')}}</button>
                </div>
              </div>
            </div>

          </div>
          <div class="w-100">
            <div class="row">
              <div class="col-md-6">
                <base-input :label="$t('message.name')" :placeholder="$t('message.name')" v-model="tempUser.name" :validator="$v.tempUser.name"/>
              </div>
              <div class="col-md-3">
                <base-input :label="$t('message.email')" :placeholder="$t('message.email')" v-model="tempUser.email" :validator="$v.tempUser.email"/>
              </div>
              <div class="col-md-3">
                  <base-input :label="$t('message.new_password')" type="password" autocomplete="new-password" :placeholder="$t('message.password')" v-model="tempUser.password"/>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <base-input :label="$t('message.language')">
                  <el-select
                    v-model="tempUser.lang"
                          class="select-primary"
                          :placeholder="$t('message.language')">
                      <el-option v-for="option in locales"
                                class="select-primary"
                                :value="option"
                                :label="$t(`message.lang_${option}`)"
                                :key="option">
                      </el-option>
                    </el-select>
                </base-input>
                </div>
              <div class="col-md-3">
                <base-input :label="$t('message.position')" :placeholder="$t('message.position')" v-model="tempUser.position"/>
              </div>
              <div class="col-md-3">
              </div>
                <div class="col-md-3">
                  <base-input v-if="tempUser.id" type="password" autocomplete="new-password" :label="$t('message.old_password')" :placeholder="$t('message.password')" v-model="tempUser.old_password"/>
              </div>
          </div>
          <div class="row">
              <div class="col-md-3">
                <base-input :label="$t('message.personalnummer')" :placeholder="$t('message.personalnummer')" v-model="tempUser.personalnummer" :validator="$v.tempUser.personalnummer"/>
              </div>
              <div class="col-md-3">
                <base-input :label="$t('message.customer')" :placeholder="$t('message.customer')" v-model="tempUser.customer_no"/>
              </div>
          </div>

        </div>

        </div>
      </div>

      <!-- Groups & rights -->
       <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">{{$t('message.roles_rights')}}</h3>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <!-- Form groups used in grid -->
          <div class="row">
            <div class="col-md-3">
              <base-input :label="$t('message.user_roles')" :validator="$v.tempUser.role_id">
                <el-select
                v-model="tempUser.role_id"
                      class="select-primary"
                      :placeholder="$t('message.user_roles')">
                  <el-option v-for="option in roles"
                            class="select-primary"
                            :value="option.id"
                            :label="option.name"
                            :key="option.id">
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>

        </div>
      </div>

      <!-- Mail permissions -->
       <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">{{$t('message.mail_permission')}}</h3>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <!-- Form groups used in grid -->
          <div class="row">
            <div class="col-md-12">
              <base-input :label="$t('message.mail_permission')">
                <el-checkbox-group v-model="mailPermissions">
                  <el-checkbox label="REPORT">{{$t('message.mail.daily_report')}}</el-checkbox>
                </el-checkbox-group>
              </base-input>
            </div>
          </div>

        </div>
      </div>

      <!-- Adresse -->
       <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">{{$t('message.address')}}</h3>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <!-- Form groups used in grid -->
          <div class="row">
            <div class="col-md-3">
              <!-- <base-input :label="$t('message.user_roles')">
                <el-select
                v-model="tempUser.role_id"
                      class="select-primary"
                      :placeholder="$t('message.user_roles')">
                  <el-option v-for="option in roles"
                            class="select-primary"
                            :value="option.id"
                            :label="option.name"
                            :key="option.id">
                  </el-option>
                </el-select>
              </base-input> -->
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import FileUpload from 'vue-upload-component'
  import { required, email, numeric } from "vuelidate/lib/validators";
  import Cropper from 'cropperjs'

  export default {
    name: 'RolesView',
    components: {
      FileUpload
    },
    props: ['data', 'employees'],
    computed: {
      ...mapGetters([
        'roles',
        'user',
        'locales'
      ])
    },
    data() {
      return {
        avatarFiles: [],
        avatarEdit: false,
        avatarCropper: false,

        tempUser: {
          name: '',
          email: '',
          password: '',
          lang: 'de',
          position: '',
          role_id: ''
        },
        avatar: null,
        oldAvatar: null,
        role: '',
        addUser: false,
        vehicle: null,
        employee: null,
        project: {
          date: null
        },
        modals: {
          addHotel: false,
          deleteHotel: false
        },
        dateTimePicker: {
          enableTime: true,
          dateFormat: 'd-m-Y H:i'
        },
        files: null,
        mailPermissions: [],
      }
    },
    validations: {
      tempUser: {
        name: {required},
        email: {required, email},
        personalnummer: {required, numeric},
        role_id: {required}
      }
    },
    watch: {
      avatarEdit(value) {
        if (value) {
          this.$nextTick(function () {
            if (!this.$refs.editImage) {
              return
            }
            let avatarCropper = new Cropper(this.$refs.editImage, {
              aspectRatio: 1 / 1,
              viewMode: 1,
            })
            this.avatarCropper = avatarCropper
          })
        } else {
          if (this.avatarCropper) {
            this.avatarCropper.destroy()
            this.avatarCropper = false
          }
        }
      }
    },


    methods: {
      ...mapActions([
        'getUser',
        'getDocs'
      ]),

      avatarEditSave() {
        this.avatarEdit = false
        let oldFile = this.avatarFiles[0]
        let binStr = atob(this.avatarCropper.getCroppedCanvas().toDataURL(oldFile.type).split(',')[1])
        let arr = new Uint8Array(binStr.length)
        for (let i = 0; i < binStr.length; i++) {
          arr[i] = binStr.charCodeAt(i)
        }
        let file = new File([arr], oldFile.name, { type: oldFile.type })

        this.$refs.upload.update(oldFile.id, {
          file,
          type: file.type,
          size: file.size,
          active: true,
        })
        this.avatar = {
          url: URL.createObjectURL(file)
        }
        this.files = {
          'avatar': [file]
        }
      },

      inputFile(newFile, oldFile, prevent) {
        if (newFile && !oldFile) {
          this.$nextTick(function () {
            this.avatarEdit = true
          })
        }
        if (!newFile && oldFile) {
          this.avatarEdit = false
        }
      },

      inputFilter(newFile, oldFile, prevent) {
        if (newFile && !oldFile) {
          if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
            return prevent()
          }
        }
        if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
          newFile.url = ''
          let URL = window.URL || window.webkitURL
          if (URL && URL.createObjectURL) {
            newFile.url = URL.createObjectURL(newFile.file)
            this.avatar = {
              url: newFile.url
            }
          }
        }
      },

      save () {
        this.$v.$touch();

        if (this.$v.$invalid) {
          console.log("Validation failed", this.$v);
          return
        }
        this.tempUser.mail_permission = this.mailPermissions.join(',')

        const done = this.tempUser.id
          ? this.$store.dispatch('updateUser', { id: this.tempUser.id, data: this.tempUser })
          : this.$store.dispatch('createUser', {data: this.tempUser });

        done
          .then(res => this.tempUser.id = res.id)
          .then(() => this.uploadFile())
          .then(() => {
            this.$notify({
              title: this.$t('message.saved'),
              message: this.$t('message.user_saved'),
              type: 'success',
            })
          })
          .then(() => this.$router.push({ path: '/admin/users'}))
          .catch(error => {
            this.$notify({
              title: 'Error',
              message: error.body ? error.body.message : error,
              type: 'error'
            })
          })
      },

      async uploadFile () {
        // if (this.avatar && !this.avatar.id && this.oldAvatar) {
        //   await this.$store.dispatch('deleteDoc', this.oldAvatar)
        // }
        if (this.files) {
          return await this.$store.dispatch('uploadDocument', { type:'users', id: this.tempUser.id, files: this.files, api: true })
        } else {
          return Promise.resolve()
        }
      },

      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('Avatar picture must be JPG format!');
        }
        if (!isLt2M) {
          this.$message.error('Avatar picture size can not exceed 2MB!');
        }
        return isJPG && isLt2M;
      },

      clearAvatar () {
        this.$refs.upload.clear();
      },

      deleteAvatar: async function () {
        try {
          await this.$confirm(
            this.$t('message.confirm_delete_avatar'),
            this.$t('message.delete'),
            {
              confirmButtonText: this.$t('message.yes'),
              cancelButtonText: this.$t('message.cancel'),
              type: "warning",
              center: false
            }
          );
        } catch (error) {
          return;
        }
        await this.$store.dispatch('deleteDocApi', [this.avatar])
        this.avatar = null
      }
    },

    mounted () {
      if (this.$route.params.userId === 'add') {
        this.tempUser = {
          name: '',
          email: '',
          password: '',
          lang: 'de',
          position: '',
          role_id: ''
        }
        this.mailPermissions = []
      } else {
        this.getUser({ id: this.$route.params.userId })
          .then(user => this.getDocs({ type: 'users', id: user.id, api: true })
          .then(docs => {
              this.avatar = this.oldAvatar = docs[0]
          }))
          .then(() => {
            this.tempUser = Object.assign({}, this.user)
            this.mailPermissions = this.tempUser.mail_permission
              ? this.tempUser.mail_permission.split(',')
              : []
          })
      }
    }
  }
</script>
<style>

.apa-avatar .avatar-edit-image {
  max-height: 300px;
  max-width: 300px !important;
}

.apa-avatar .avatar-upload .rounded-circle {
  width: 200px;
  height: 200px;
}
.apa-avatar .avatar-upload .avatar-preview {
  cursor: pointer;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 200px;
  height: 200px;
}
.apa-avatar .avatar-upload .avatar-preview:hover {
  opacity: .8;
  border-color: #9f2f37;
}

.apa-avatar .avatar-upload .avatar-preview img {
    width: 100%;
    object-fit: cover;
}

.apa-avatar .text-center .btn {
  margin: 0 .5rem
}
.apa-avatar .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: .6;
  text-align: center;
  background: #000;
}
.apa-avatar .drop-active h3 {
  margin: -.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
</style>
