<template>
  <modal @update:show="onShowChange" :show="show">
    <h6
      slot="header"
      class="modal-title"
    >{{ teamMember.id ? this.$t('message.teamEdit') : this.$t('message.teamNew') }}</h6>

    <div v-loading="isLoading">
      <base-input :label="$t('message.teamMemberPlus')">
        <el-select
          filterable
          :placeholder="$t('message.teamMemberSelect')"
          v-model="$v.teamMember.user_id.$model"
        >
          <el-option v-for="user in users" :key="user.id" :label="user.name" :value="user.id"></el-option>
        </el-select>
      </base-input>

      <base-input :label="$t('message.locationPlus')">
        <el-select
          class="select-primary"
          multiple
          :placeholder="$t('message.locationSelect')"
          v-model="$v.teamMemberAddresses.$model"
        >
          <el-option
            class="select-primary"
            v-for="address in addresses"
            :value="address.address.id"
            :label="address.address.name"
            :key="address.address.id"
          ></el-option>
        </el-select>
      </base-input>

      <base-input :label="$t('message.responsibilityPlus')">
        <el-select
          filterable
          :placeholder="$t('message.responsibilitySelect')"
          v-model="$v.teamMember.topic.$model"
        >
          <el-option v-for="role in internalPersonTopics" :key="role.id" :label="role.name" :value="role.val"></el-option>
        </el-select>
      </base-input>
    </div>

    <template slot="footer">
      <base-button type="link" class="ml-auto" @click="onClose">{{$t('message.cancel')}}</base-button>
      <base-button type="primary" @click="onSave" :disabled="$v.$invalid">{{$t('message.save')}}</base-button>
    </template>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { required, minLength, between } from "vuelidate/lib/validators"
import api from "@/util/api.js"


export default {
  name: "team-form",
  props: {
    project: {
      type: Object
    },
    show: {
      type: Boolean,
      default: false
    },
    teamMember: {
      type: Object
    }
  },
  data() {
    return {
      addresses: [],
      isLoading: true,
      users: []
    };
  },
  computed: {
    ...mapGetters([
      'internalPersonTopics'
    ]),
    teamMemberAddresses: {
      get() {
        return this.validateAddresses(this.teamMember.addresses);
      },
      set(value) {
        this.teamMember.addresses = value;
      }
    }
  },
  validations: {
    teamMember: {
      user_id: {
        required
      },
      topic: {
        required
      }
    },
    teamMemberAddresses: {}
  },
  methods: {
    ...mapActions([
      "createPerson_address",
      "createProject_person",
      "deleteProjectPersonAddresses",
      "loadUsers",
      "findPerson_addresses",
      "getAddressesByProjectId",
      "updateProject_person"
    ]),
    load: async function() {
      this.isLoading = true;
      this.addresses = await this.getAddressesByProjectId(this.project.id);
      const allUsers = await this.loadUsers()
      this.users = allUsers.filter(u => u.role.name !== 'Kunde' && u.role.name !== 'Gast' && u.role_id !== 18)
      this.isLoading = false;
    },
    onClose() {
      this.onShowChange(false);
    },
    onSave: async function() {
      const { project, teamMember } = this;
      const { addresses, id, user, hotel, ...data } = teamMember;
      data.project_id = project.id;

      this.isLoading = true;

      if (id) {
        await this.updateProject_person({ id, data });
        await this.deleteProjectPersonAddresses({
          projectId: project.id,
          userId: data.user_id
        });
      } else {
        await this.createProject_person({ data });
      }

      await this.saveAddresses(addresses, teamMember.user_id);

      this.isLoading = false;
      this.onClose();
      this.$emit("changed");
    },
    onShowChange(value) {
      this.$emit("update:show", value);
    },
    saveAddresses: async function(address_ids, user_id) {
      const { id: project_id } = this.project;
      await Promise.all(
        this.validateAddresses(address_ids).map(address_id =>
          this.createPerson_address({
            data: { address_id, project_id, user_id }
          })
        )
      );
      await api("findPerson_addresses")
              .and("project_id", this.project.id)
              .with("address")
              .with("user", { one: "users", this: "user_id" })
              .with("hotel", { one: "address", this: "hotel_id" })
              .run()
    },
    validateAddresses(address_ids) {
      const { addresses } = this;

      if (address_ids==null) return [];

      return address_ids
        .filter(address_id =>
          addresses.some(address => address.address.id === address_id)
        )
        .reduce(
          (address_ids, address_id) =>
            address_ids.indexOf(address_id) === -1
              ? [...address_ids, address_id]
              : address_ids,
          []
        );
    }
  },
  mounted() {
    this.load();
  }
};
</script>
