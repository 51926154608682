<template>
  <modal @update:show="onShowChange" :show="show">
    <h6 slot="header" class="modal-title">
      {{ external.id ? $t("message.editExternal") : $t("message.newExternal") }}
    </h6>

    <div v-loading="isLoading">
      <base-input :label="$t('message.contactPerson')">
        <el-select
          v-model="external.user_id"
          filterable
          :placeholder="$t('message.contactPersonSelect')"
          :disabled="external.id != null"
        >
          <el-option
            :label="$t('message.newUserBrackets')"
            value="NEW"
          ></el-option>
          <el-option
            v-for="user in users"
            :key="user.id"
            :label="user.name"
            :value="user.id"
          ></el-option>
        </el-select>
      </base-input>
      <div
        v-if="external.user_id == 'NEW'"
        class="border-left border-primary pt-1 pl-4"
      >
        <base-input
          type="text"
          :label="$t('message.nameComplete')"
          v-model="name"
        ></base-input>
        <base-input
          type="text"
          :label="$t('message.phone')"
          v-model="phone"
        ></base-input>
        <base-input type="email" label="E-Mail" v-model="email"></base-input>

        <base-input label="Rolle" :validator="$v.customerRole">
          <el-radio v-model="customerRole" label="Kunde"></el-radio>
          <el-radio v-model="customerRole" label="Gast"></el-radio>
        </base-input>
      </div>

      <base-input :label="$t('message.responsible') + ' *'">
        <el-select
          v-model="$v.selectedTopics.$model"
          filterable
          multiple
          :placeholder="$t('message.responsibilitySelect')"
        >
          <el-option
            v-for="role in externalPersonTopics"
            :key="role.id"
            :label="role.name"
            :value="role.val"
          ></el-option>
        </el-select>
      </base-input>
      <div v-if="$v.selectedTopics.$error" class="invalid-feedback d-block mt--3 ml-1 mb-2">
        <span>Bitte wählen Sie mind. eine Option aus.</span>
      </div>

      <base-checkbox v-model="external.get_report">{{
        $t("message.get_report")
      }}</base-checkbox>
    </div>

    <template slot="footer">
      <base-button type="link" class="ml-auto" @click="onClose">{{
        $t("message.cancel")
      }}</base-button>
      <base-button type="secondary" @click="onSave(true)">{{
        $t("message.saveAndInvite")
      }}</base-button>
      <base-button type="primary" @click="onSave(false)">{{
        $t("message.save")
      }}</base-button>
    </template>
  </modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import omit from "lodash/omit";
export default {
  name: "externals-form",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    external: {
      type: Object,
    },
    projectId: {
      type: Number,
    },
  },
  validations: {
    customerRole: { required },
    selectedTopics: { required, minLength: minLength(1) }
  },
  computed: {
    ...mapGetters(["project", "externalPersonTopics"]),
  },
  watch: {
    external() {
      this.selectedTopics = this.external.topic
        ? this.external.topic.split(",")
        : [];
    },
  },
  data() {
    return {
      isLoading: false,
      user: null,
      inviteUser: false,
      externalRoles: null,
      role: null,
      name: "",
      email: "",
      phone: "",
      selectedTopics: this.external.topic ? this.external.topic.split(",") : [],
      users: [],
      customerRole: null
    };
  },
  methods: {
    ...mapActions([
      "createPerson_address",
      "createProject_person",
      "deleteProjectPersonAddresses",
      "findUsers",
      "findPerson_addresses",
      "getAddressesByProjectId",
      "updateProject_person",
    ]),
    onClose() {
      this.onShowChange(false);
    },
    onSave: async function (invite) {
      this.$v.$touch();
      if (this.$v.$invalid && (this.external.user_id == "NEW" || this.selectedTopics.length < 1)) {
        return;
      }
      
      this.isLoading = true;

      this.external.topic = this.selectedTopics.join(",");

      // TODO: Add save logic
      let p = Promise.resolve();
      if (!this.external.id) {
        if (this.external.user_id == "NEW") {
          p = p
            .then(() =>
              this.$store.dispatch("findRoles", {
                data: { and: { name: this.customerRole } },
              })
            )
            .then((roles) => {
              if (roles.length == 0)
                throw { body: { message: `Rolle '${this.customerRole}' nicht gefunden.` } };
              if (roles.length > 1)
                throw { body: { message: `Rolle '${this.customerRole}' nicht eindeutig.` } };

              return {
                name: this.name,
                email: this.email,
                phone: this.phone,
                password: "",
                lang: "de",
                position: "",
                role_id: roles[0].id,
              };
            });

          p = p
            .then((newUser) =>
              this.$store.dispatch("createUser", { data: newUser })
            )
            .then((res) => (this.external.user_id = res.id))
            .then(() => this.$store.dispatch("findUsers"));
        }
        p = p.then(() => {
          return this.createProject_person({ data: this.external });
        });
      } else {
        p = this.updateProject_person({
          id: this.external.id,
          data: omit(this.external, ["addresses", "user"]),
        });
      }
      if (invite) {
        // Kleiner Trick: Update auf dem User mit dem invite-Flag,
        // triggert auf dem Server das Senden der Mail.
        p = p.then(() =>
          this.$store.dispatch("updateUser", {
            id: this.external.user_id,
            data: { invite: this.project.key + " " + this.project.name },
          })
        );
      }
      p.then(() => {
        this.isLoading = false;
        this.$emit("changed");
        this.onClose();
      });
      p.catch((error) => {
        this.isLoading = false;
        this.$notify({
          title: "Error",
          message: error.body ? error.body.message : error,
          type: "error",
        });
      });
    },
    load: async function() {
      this.isLoading = true;
      const users = await this.$store.dispatch("findUsers", {
        data: {
          and: this.filter
        },
        options: {
          commit: false
        }
      });

      this.isLoading = false;
      this.users = users.filter((u) => u.role_id !== 18).sort((left, right) =>
        left.name.localeCompare(right.name)
      )
    },
    onShowChange(state) {
      this.$emit("update:show", state);
    },
  },
  mounted() {
    this.load();
  },
};
</script>
