var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-header',{staticClass:"pb-6"},[_c('div',{staticClass:"row align-items-center py-4"},[_c('div',{staticClass:"col-lg-6 col-7"},[_c('nav',{staticClass:"d-none d-md-inline-block ml-md-4",attrs:{"aria-label":"breadcrumb"}},[_c('route-breadcrumb')],1)]),_c('div',{staticClass:"col-lg-6 col-5 text-right"})])]),_c('div',{staticClass:"container-fluid mt--6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"border-0 card-header"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-6 text-right"},[_c('base-button',{attrs:{"type":"primary","size":"sm"},on:{"click":function($event){return _vm.$router.push({ path: '/admin/teams/form/new'})}}},[_vm._v(" Neues Team ")])],1)])]),_c('el-table',{staticClass:"table-responsive table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.items}},[_c('el-table-column',{attrs:{"label":"Name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.name)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Mitglieder"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.teamMember(row.user_ids))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Teamchef"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.teamLeader(row.team_leader_id))+" ")]}}])}),_c('el-table-column',{attrs:{"align":"right","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-dropdown',{staticClass:"dropdown",attrs:{"trigger":"click"}},[_c('span',{staticClass:"btn btn-sm btn-icon-only text-light"},[_c('i',{staticClass:"fas fa-ellipsis-v mt-2"})]),_c('el-dropdown-menu',{staticClass:"dropdown-menu dropdown-menu-arrow show",attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({ path: '/admin/teams/form/'+row.id})}}},[_vm._v("Bearbeiten")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.remove(row.id)}}},[_vm._v("Löschen")])])],1)]}}])})],1)],1)])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-6"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Aufgabenteams")])])}]

export { render, staticRenderFns }