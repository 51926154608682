<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <!-- <h6 class="h2 d-inline-block mb-0">{{$route.name}}</h6> -->
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="primary" @click="save">Speichern</base-button>
          <base-button size="sm" type="neutral" @click="$router.push({ path: '/admin/articles'})">Abbrechen</base-button>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">Material</h3>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <base-input v-model="item.ItemNo" label="Artikelnummer" disabled="true"/>
            </div>
            <div class="col-md-4">
              <base-input v-model="item.Code" label="Variante" disabled="true"/>
            </div>
            <div class="col-md-4">
              <base-input v-model="item.Description" label="Beschreibung" disabled="true"/>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <base-input v-model="item.delivery_time" label="Lieferzeit"/>
            </div>
            <div class="col-md-4">
              <base-input v-model="item.buffer_time" label="Pufferzeit"/>
            </div>
            <div class="col-md-4">
              <base-input v-model="item.weight" label="Gewicht (kg)"/>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <base-input label="Brandschutz">
                <el-input type="textarea" autosize v-model="item.brandschutz"></el-input>
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <base-input label="Preis in Prozent">
                <el-checkbox v-model="item.price_percent">
                  Bei diesem Artikel in dieser Variante wird ein Preis in Prozent erfragt,
                  der dann dem gesamten Projektpreis hinzu addiert wird.
                </el-checkbox>
              </base-input>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'RolesView',
    components: {

    },
    props: ['data', 'employees'],
    computed: {
    },
    mounted () {
      if (!this.getId()) {
        this.item = {}
      } else {
        this.$store.dispatch('findVariants', {data: {and: {'id': this.getId()}}}).then(items=>{
          this.item=items[0]
          this.item.price_percent = !!this.item.price_percent
        })
      }
    },
    data() {
      return {
        item: {}
      }
    },
    methods: {
      getId() {
        return this.$route.params.id=='new' ? null : this.$route.params.id;
      },
      async save() {
        await this.$store.dispatch('updateVariant', {id: this.item.id, data: this.item});
        this.$router.push({ path: '/admin/articles'})
      }
    }
  }
</script>
