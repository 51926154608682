<template>
  <div class="card" v-loading="isLoading">
    <div class="card-header">
      <div class="d-flex">
        <h3 class="m-0 pr-4 flex-grow-1">{{$t('message.transportInsurance')}}</h3>
        <el-tooltip :content="$t('message.new')" placement="top">
          <base-button type="primary" icon round size="sm" @click="onCreate" :disabled="project.state === 'ARCHIVED'">
            <i class="fas fa-plus p-0"></i>
          </base-button>
        </el-tooltip>
      </div>
    </div>

    <el-table
      class="table-responsive table-compact"
      header-row-class-name="thead-light"
      row-key="id"
      :data="insurances"
    >
      <el-table-column :label="$t('message.loadingDate')" prop="load" sortable>
        <template v-slot="{row}">
          {{ row.ride.load | datetime }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('message.licensePlate')" prop="ride.vehicle.license" sortable />
      <el-table-column :label="$t('message.value')" prop="amount" sortable>
        <template v-slot="{row}">
          {{row.amount | currency}}
        </template>
      </el-table-column>
      <el-table-column label width="88">
        <template v-slot="{row}">
          <el-tooltip :content="$t('message.edit')" placement="top">
            <base-button type="link" size="sm" @click="onEdit(row)" :disabled="project.state === 'ARCHIVED'">
              <i class="fas fa-edit text-black"></i>
            </base-button>
          </el-tooltip>
          <el-tooltip :content="$t('message.delete')" placement="top">
            <base-button type="link" size="sm" @click="onDelete(row)" :disabled="project.state === 'ARCHIVED'">
              <i class="fas fa-trash text-black"></i>
            </base-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <insurance-form :insurance="insurance" :rides="rides" :show.sync="showForm" @changed="onChanged" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import api from "@/util/api.js";

import InsuranceForm from "./InsuranceForm";

export default {
  name: "Insurances",
  components: {
    InsuranceForm
  },
  props: ["project"],
  mounted() {
    this.load();
  },
  computed: {
    ...mapGetters(["projectAddresses"])
  },
  data() {
    return {
      isLoading: true,
      insurances: [],
      insurance: {},
      rides: [],
      showForm: false
    };
  },
  methods: {
    load: async function() {
      this.insurances = await this.$store.dispatch("findTransport_insurances", {data:{
        and: {project_id: this.project.id},
        with: {
          ride: {
            one: 'ride',
            this: 'ride_id',
            query: {
              with: {
                vehicle: {one: 'vehicle', this: 'vehicle_id'}
              }
            }
          }
        }
      }})
      this.rides = await this.$store.dispatch("findRides", {data:{
        and: {project_id: this.project.id},
        with: {
          vehicle: {one: 'vehicle', this: 'vehicle_id'}
        }
      }});

      this.isLoading = false;
    },
    onChanged() {
      this.isLoading = true;
      this.load();
    },
    onCreate() {
      const { projectAddresses, insurances } = this;
      this.insurance = {
        project_id: this.project.id,
      };

      this.showForm = true;
    },
    onEdit(insurance) {
      this.insurance = { ...insurance };
      this.showForm = true;
    },
    onDelete: async function(item) {
      try {
        await this.$confirm(
          `Wollen Sie diese Fahrt wirklich löschen?`,
          `Fahrt löschen`,
          {
            confirmButtonText: this.$t("message.yes"),
            cancelButtonText: this.$t("message.no"),
            type: "warning"
          }
        );
      } catch (error) {
        return;
      }

      this.isLoading = true;
      await this.$store.dispatch("deleteTransport_insurance", { id: item.id });
      this.load();
    }
  }
};
</script>
