<template>
  <div>
    <base-header class="pb-6 content__title content__title--calendar">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb />
          </nav>
        </div>
        <div class="col-lg-6 mt-3 mt-lg-0 text-lg-right">

        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
              <div class="d-md-flex">
                <h5 class="h3 mb-0 flex-grow-1">{{ $t('message.debriefing') }}</h5>
                <base-button type="primary" size="sm" @click="showProjectForm" v-if="currentUserRights.includes('debriefing.project_form.cansee')">
                  Formular Projekt
                </base-button> 
                <base-button type="primary" size="sm" @click="showTechnicForm" v-if="currentUserRights.includes('debriefing.technic_form.cansee')">
                  Formular Technik
                </base-button> 
                <base-button type="primary" size="sm" @click="showServiceForm" v-if="currentUserRights.includes('debriefing.service_form.cansee')">
                  Formular Dienstleister
                </base-button> 
              </div>
            </div>
            <!-- Card body -->
            <div class="card-body">
              <debriefing-comments :fixtures="{
                project_id: $route.params.id,
                type: 'DEBRIEFING',
              }" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <project-form :show.sync="isProjectFormVisible"></project-form>
    <technic-form :show.sync="isTechnicFormVisible"></technic-form>
    <service-form :show.sync="isServiceFormVisible"></service-form>

  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import DebriefingComments from '@/components/ApaComments/DebriefingComments.vue'
import ProjectForm from './ProjectForm.vue'
import TechnicForm from './TechnicForm.vue'
import ServiceForm from './ServiceForm.vue'

export default {
  name: 'debriefing',
  components: {
    DebriefingComments,
    ProjectForm,
    TechnicForm,
    ServiceForm
  },
  data() {
    return {
      isProjectFormVisible: false,
      isTechnicFormVisible: false,
      isServiceFormVisible: false
    }
  },
  computed: {
    ...mapGetters([
      "currentUserRights"
    ])
  },
  methods: {
    ...mapActions([
      "getFullProject",
      "getProjectPersons"
    ]),
    showProjectForm() {
      this.isProjectFormVisible = true
    },
    showTechnicForm() {
      this.isTechnicFormVisible = true
    },
    showServiceForm() {
      this.isServiceFormVisible = true
    }
  }
};
</script>
